<template>
  <div class="overflow-hidden" ref="tiktokContainer" :style="{
    height: `${tiktokContainerSize}px`
  }">
    <blockquote class="overflow-y-hidden tiktok-embed w-full border-none shadow-none outline-0 block p-0" :cite="url"
      :data-video-id="videoID" :style="{
        transform: `scale(${tiktokContainerSize * 0.00134})`, transformOrigin: 'top center',
        marginTop: 0
      }">
      <div class="bg-grey-200 flex flex-col items-center">
        <p class="text-center">Loading Animation TBC</p>
        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-base-grey"></div>
      </div>
    </blockquote>
  </div>
</template>

<script setup lang="ts">
import { useWindowSize } from "@vueuse/core";
import { onMounted, ref, nextTick, watch } from "vue";

const props = defineProps<{
  url: string;
}>()

const TIKTOK_HEIGHT_BOUNDS = [580, 800]
const tiktokContainerSize = ref(580)
const tiktokContainer = ref<HTMLElement>()

const { height } = useWindowSize()

const inBoundsElse = (value: number, fallback: number, min: number, max: number) => {
  const inBounds = Math.max(Math.min(value, max), min) === value
  if (inBounds) return value
  return fallback
}

watch(() => [tiktokContainer.value?.getBoundingClientRect().top, window.scrollY, height.value], () => {
  if (!tiktokContainer.value) return

  const distanceFromTop = tiktokContainer.value.offsetTop
  tiktokContainerSize.value = inBoundsElse(
    height.value - distanceFromTop,
    TIKTOK_HEIGHT_BOUNDS[1],
    TIKTOK_HEIGHT_BOUNDS[0],
    TIKTOK_HEIGHT_BOUNDS[1]
  )
}, { immediate: true })

const videoID = ref('')

const injectScript = () => {
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = '//www.tiktok.com/embed.js';
  document.body.appendChild(script);
}

interface TikTokScript {
  tiktokEmbed: {
    lib: {
      render: (elements: HTMLElement[]) => void
    }
  }
}

const isTikTokWindow = (window: Window): window is Window & TikTokScript => {
  return (window as any).tiktokEmbed !== undefined
}

onMounted(async () => {
  videoID.value = props.url.split('/')[5].split('?')[0];

  nextTick(() => {
    const tikTokBlockquote = document.querySelector('blockquote.tiktok-embed') as HTMLElement
    if (isTikTokWindow(window) && tikTokBlockquote) {
      window.tiktokEmbed.lib.render([tikTokBlockquote])
    }
    else {
      injectScript();
    }
  })
})
</script>
