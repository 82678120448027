<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute, useRouter, RouterView } from 'vue-router'
import { usePinsStore } from '@/stores/pins'
import MapboxMap from '@/components/MapboxMap.vue'
import ContentSlideout from '@/components/ContentSlideout.vue'
import { useMapStore } from '@/stores/map'
import * as Fathom from 'fathom-client';
import { useLanguagesStore } from "@/stores/languages";
import { useAppStateStore } from "@/stores/appState";
import logoImageDefault from '@/assets/images/logo.webp'
import logoImageFrench from '@/assets/images/logo-French.webp'

const languagesStore = useLanguagesStore()

const logo = languagesStore.getActiveLanguage().id === 'fr' ? logoImageFrench : logoImageDefault

// At the earliest point possible, if the user is on a URL that is not in their
// language, redirect them to the correct language.
if (languagesStore.getUserLanguage().id != languagesStore.getActiveLanguage().id) {
  // Allow the new language if we're not on the default language home page.
  if (window.location.pathname !== '/') {
    localStorage.setItem('languageManuallySet', languagesStore.getActiveLanguage().id)
  }
  // Otherwise send the user to their language.
  else {
    window.location.href = `/${languagesStore.getLanguageURLPrefix(languagesStore.getUserLanguage())}`
  }
}

const appStateStore = useAppStateStore()
const mapStore = useMapStore()
const pinsStore = usePinsStore()

const router = useRouter()
const route = useRoute()
const noMap = ref(true)

if (import.meta.env['VITE_FATHOM_SITE_ID']) {
  Fathom.load(import.meta.env['VITE_FATHOM_SITE_ID'], { spa: 'history' })
}

watch(() => route.meta, () => {
  noMap.value = route.meta.noMap === true
});

// If no pin is active and the closest tier 1 event has changed we navigate to it.
watch(() => mapStore.closeTier1Pin, () => {
  if (!mapStore.closeTier1Pin) return
  if (!pinsStore.activePin && mapStore.closeTier1Pin) {
    Fathom.trackEvent(`Closest tier 1 event set: ${mapStore.closeTier1Pin?.alias}`)
    router.push({
      name: `stories:${languagesStore.getActiveLanguage().id}`,
      params: { alias: mapStore.closeTier1Pin?.alias }
    })
  }
})

watch(() => pinsStore.activePin, () => {
  if (pinsStore.activePin) {
    console.log('ACTIVE PIN WAS CHANGED', pinsStore.activePin)
    router.push({
      name: `stories:${languagesStore.getActiveLanguage().id}`,
      params: { alias: pinsStore.activePin.alias }
    })
  }
})

watch(() => mapStore.mapMoved, () => {
  if (mapStore.mapMoved) {
    const mapCenter = mapStore.map?.getCenter() || { lng: 0, lat: 0 }

    mapStore.mapMoved = false
    mapStore.findCloseTier1Pin()
    if (!pinsStore.activePin) {
      router.replace({ name: `geoView:${languagesStore.getActiveLanguage().id}`, hash: `#${mapCenter.lat},${mapCenter.lng}` })
    }
  }
})

watch(() => route.path, () => {
  // Record the page count, so we can check later if we're on the first page.
  appStateStore.updatePageCount()
}, { immediate: true })
</script>

<template>
  <div v-if="noMap">
    <RouterView />
  </div>
  <div :class="{
    'flex justify-center bg-base-grey': mapStore.mapCreationError
  }" v-else>
    <main class="items-center w-screen lg:w-[calc(100dvw-var(--sidebar-lg-width))] xl:w-[calc(100dvw-var(--sidebar-xl-width))] bg-base-grey fixed">
      <MapboxMap />
      <RouterLink :to="{name: `home:${languagesStore.getActiveLanguage().id}`}" @click="appStateStore.pageCount = -1">
        <img v-if="!mapStore.mapCreationError" alt="Foodmarks logo" width="188" height="183" class="absolute top-4 right-6 lg:top-6 lg:right-9 z-50" :src="logo" />
      </RouterLink>
    </main>
    <ContentSlideout />
  </div>
</template>
