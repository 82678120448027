<template>
  <div class="space-y-6 px-[1.88rem]">
    <h2 class="border-b-[3px] mb-3 border-timeout-red text-base-grey text-2xl leading-normal font-serif mt-6"
        v-html="appStringsStore.get('tasteMakers')"></h2>
    <div class="space-x-0 flex-row grid grid-cols-2 gap-3 w-full min-h-max pb-2">
      <template v-for="story in props.stories" :key="story.id">
        <PinImage :size="PinSize.Medium" :pin="story" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PinSize, type Pin } from '@/types'
import PinImage from '@/components/PinImage.vue'
import {useAppStringsStore} from "@/stores/appStrings";

const appStringsStore = useAppStringsStore()

const props = defineProps<{
  stories: Pin[]
}>()
</script>

