<template>
  <iframe class="mt-6" :src="embedURL" width="100%" :height="height + 45" frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import parseAspectRatio from "@/utils/parseAspectRatio";

const props = defineProps<{
  url: string;
  aspectRatio: string;
}>()

const videoID = props.url.split('/')[3];
const embedURL = ref(videoID ? `https://player.vimeo.com/video/${videoID}?autoplay=1&muted=1` : '')

// Default ratio is 300 x 540.


const height = computed(() => {
  const [multiplier, error] = parseAspectRatio(props.aspectRatio);
  const width = 480

  if (error) {
    console.log(error.message, `(${props.aspectRatio}) for`, props.url)
    return width * (540 / 300)
  }

  return width * multiplier
})
</script>
