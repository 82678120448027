<template>
  <div :style="{
    height: `${reelsContainerSize}px`,
  }" ref="reelsContainer" class="w-full flex justify-center">
    <iframe :style="{
      transform: `scale(${reelsContainerSize * 0.0014})`, transformOrigin: 'top center',
    }" class="overflow-y-hidden" id="embed" width="400" height="700" :src="`${url}embed/`" frameborder="0" autoplay
      scrolling="no"></iframe>
  </div>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core';
import { ref, watch } from 'vue';

defineProps<{
  url: string;
}>()

const REELS_HEIGHT_BOUNDS = [480, 700]
const reelsContainerSize = ref(200)
const reelsContainer = ref<HTMLElement>()

const { height } = useWindowSize()

const inBoundsElse = (value: number, fallback: number, min: number, max: number) => {
  const inBounds = Math.max(Math.min(value, max), min) === value
  if (inBounds) return value
  return fallback
}

watch(() => [reelsContainer.value?.getBoundingClientRect().top, window.scrollY, height.value], () => {
  if (!reelsContainer.value) return

  const distanceFromTop = reelsContainer.value.offsetTop
  reelsContainerSize.value = inBoundsElse(
    height.value - distanceFromTop,
    REELS_HEIGHT_BOUNDS[1],
    REELS_HEIGHT_BOUNDS[0],
    REELS_HEIGHT_BOUNDS[1]
  )
}, { immediate: true })



</script>
