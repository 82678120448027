<script setup lang="ts">
import MapOverlayButton from '@/components/MapOverlayButton.vue'
import { useAppStringsStore } from "@/stores/appStrings";
import { useMapStore } from '@/stores/map'

const appStringsStore = useAppStringsStore()
const mapStore = useMapStore()

defineProps<{
  showLabel?: boolean
  red?: boolean
}>()
</script>

<template>
  <MapOverlayButton>
    <svg class="w-5 h-5" v-if="mapStore.userLocation" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="2" fill="white"/>
      <path d="M-4.37114e-07 10C-1.95905e-07 15.5182 4.48179 20 10 20C15.5182 20 20 15.5182 20 10C20 4.48179 15.5182 -8.34197e-07 10 -5.92989e-07C4.48179 -3.5178e-07 -6.78731e-07 4.47246 -4.37114e-07 10ZM17.6284 7.86181C17.8245 8.55275 17.2549 9.23436 16.5359 9.23436L15.4715 9.23436C15.0514 9.23436 14.7059 9.57983 14.7059 10C14.7059 10.4202 15.0514 10.7656 15.4715 10.7656L16.5359 10.7656C17.2549 10.7656 17.8151 11.4472 17.6284 12.1382C16.8908 14.7899 14.7993 16.8908 12.1382 17.6284C11.4472 17.8245 10.7656 17.2642 10.7656 16.5359L10.7656 15.4715C10.7656 15.0514 10.4202 14.7059 10 14.7059C9.57983 14.7059 9.23436 15.0514 9.23436 15.4715L9.23436 16.5359C9.23436 17.2549 8.55275 17.8151 7.86181 17.6284C5.20075 16.8908 3.10924 14.7899 2.36228 12.1382C2.1662 11.4472 2.73576 10.7656 3.45471 10.7656L4.52848 10.7656C4.94865 10.7656 5.29412 10.4202 5.29412 10C5.29412 9.57983 4.94865 9.23436 4.52848 9.23436L3.45471 9.23436C2.73576 9.23436 2.17554 8.55275 2.36228 7.86181C3.10924 5.20075 5.20075 3.10924 7.86181 2.37162C8.55275 2.17554 9.23436 2.7451 9.23436 3.46405L9.23436 4.52848C9.23436 4.94865 9.57983 5.29412 10 5.29412C10.4202 5.29412 10.7656 4.94865 10.7656 4.52848L10.7656 3.47339C10.7656 2.71709 11.5033 2.19421 12.2316 2.40896C14.8366 3.1746 16.8908 5.24743 17.619 7.87115L17.6284 7.86181Z" fill="white"/>
    </svg>
    <svg class="w-5 h-5" v-else :class="{'animate-spin duration-1000': mapStore.userLocationRetrieving}" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M-4.37114e-07 10C-1.95905e-07 15.5182 4.48179 20 10 20C15.5182 20 20 15.5182 20 10C20 4.48179 15.5182 -8.34197e-07 10 -5.92989e-07C4.48179 -3.5178e-07 -6.78731e-07 4.47246 -4.37114e-07 10ZM17.6284 7.86181C17.8245 8.55275 17.2549 9.23436 16.5359 9.23436L15.4715 9.23436C15.0514 9.23436 14.7059 9.57983 14.7059 10C14.7059 10.4202 15.0514 10.7656 15.4715 10.7656L16.5359 10.7656C17.2549 10.7656 17.8151 11.4472 17.6284 12.1382C16.8908 14.7899 14.7993 16.8908 12.1382 17.6284C11.4472 17.8245 10.7656 17.2642 10.7656 16.5359L10.7656 15.4715C10.7656 15.0514 10.4202 14.7059 10 14.7059C9.57983 14.7059 9.23436 15.0514 9.23436 15.4715L9.23436 16.5359C9.23436 17.2549 8.55275 17.8151 7.86181 17.6284C5.20075 16.8908 3.10924 14.7899 2.36228 12.1382C2.1662 11.4472 2.73576 10.7656 3.45471 10.7656L4.52848 10.7656C4.94865 10.7656 5.29412 10.4202 5.29412 10C5.29412 9.57983 4.94865 9.23436 4.52848 9.23436L3.45471 9.23436C2.73576 9.23436 2.17554 8.55275 2.36228 7.86181C3.10924 5.20075 5.20075 3.10924 7.86181 2.37162C8.55275 2.17554 9.23436 2.7451 9.23436 3.46405L9.23436 4.52848C9.23436 4.94865 9.57983 5.29412 10 5.29412C10.4202 5.29412 10.7656 4.94865 10.7656 4.52848L10.7656 3.47339C10.7656 2.71709 11.5033 2.19421 12.2316 2.40896C14.8366 3.1746 16.8908 5.24743 17.619 7.87115L17.6284 7.86181Z" fill="white"/>
    </svg>
    <span v-if="showLabel" class="whitespace-nowrap pl-3">{{ appStringsStore.get('locateMe') }}</span>
  </MapOverlayButton>
</template>
