import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StoryView from '../views/StoryView.vue'
import ContentView from "@/views/ContentView.vue";
import ErrorView from "@/views/ErrorView.vue";
import SearchView from "@/views/SearchView.vue";
import FavouritesView from "@/views/FavouritesView.vue";
import GeoView from "@/views/GeoView.vue";
import { useLanguagesStore } from "@/stores/languages";
import type { Language } from "@/types";
import FontTestView from "@/views/FontTestView.vue";
import {useMobileSlideoutStore} from "@/stores/mobileSlideout";
import useIsMobile from "@/composables/useIsMobile";

export default function useRouter() {
  const languagesStore = useLanguagesStore()
  const routes: RouteRecordRaw[] = [];
  const mobileSlideoutStore = useMobileSlideoutStore()
  const isMobile = useIsMobile()

  const getTypePrefix = (type: string, language: Language) => {
    if (language.urlTypePrefixes[`${type}URLPrefix`]) return encodeURI(language.urlTypePrefixes[`${type}URLPrefix`])
    else if (languagesStore.getDefaultLanguage().urlTypePrefixes[`${type}URLPrefix`]) return encodeURI(languagesStore.getDefaultLanguage().urlTypePrefixes[`${type}URLPrefix`])
    else return type
  }

  // Loop through the languagesStore.languages and create the routes.
  languagesStore.languages.forEach((language: Language) => {
    const prefix = languagesStore.getLanguageURLPrefix(language)

    routes.push({
      path: '/' + prefix,
      name: 'home:' + language.id,
      component: HomeView
    })
    routes.push({
      path: `/${prefix}${prefix.length === 0 ? '' : '/'}${getTypePrefix('stories', language)}/:alias(.*)`,
      name: 'stories:' + language.id,
      component: StoryView,
      meta: {typePrefix: getTypePrefix('stories', language)}
    })
    routes.push({
      path: `/${prefix}${prefix.length === 0 ? '' : '/'}${getTypePrefix('content', language)}/:alias(.*)`,
      name: 'content:' + language.id,
      component: ContentView,
      meta: {typePrefix: getTypePrefix('content', language)}
    })
    routes.push({
      path: `/${prefix}${prefix.length === 0 ? '' : '/'}${getTypePrefix('location', language)}`,
      name: 'geoView:' + language.id,
      component: GeoView,
      meta: {typePrefix: getTypePrefix('geoView', language)}
    })
    routes.push({
      path: `/${prefix}${prefix.length === 0 ? '' : '/'}${getTypePrefix('search', language)}/:query?`,
      name: 'search:' + language.id,
      component: SearchView,
      meta: {typePrefix: getTypePrefix('search', language)}
    })
    routes.push({
      path: `/${prefix}${prefix.length === 0 ? '' : '/'}${getTypePrefix('favourites', language)}`,
      name: 'favourites:' + language.id,
      component: FavouritesView,
      meta: {typePrefix: getTypePrefix('favourites', language)}
    })
    routes.push({
      path: `/${prefix}${prefix.length === 0 ? '' : '/'}:pathMatch(.*)`,
      name: 'errorView:' + language.id,
      component: ErrorView,
      meta: {
        'noMap': true
      }
    })
  })

  routes.push({
    path: '/fonts',
    name: 'fonts',
    component: FontTestView,
    meta: {
      'noMap': true
    }
  })

  console.log('ROUTES', routes)

  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: routes
  })

  router.beforeEach((to) => {
    // If the mobile slideout is open, close it before routing.
    if (isMobile && mobileSlideoutStore.ready && mobileSlideoutStore?.pane?.currentBreak() !== 'bottom') {
      mobileSlideoutStore.close()
      setTimeout(() => {
        router.push(to)
      }, 200)
      return false
    }
  })

  return router
}
