import type { UseFetchReturn } from '@vueuse/core'

export type UseFetchResponse<T> = UseFetchReturn<T | undefined>
  & PromiseLike<UseFetchReturn<T | undefined>>

export type StoryType = "featured" | "article" | "social"

export const isStoryType = (type: string): type is StoryType => {
  return ["featured", "article", "social"].includes(type)
}

export type Story = {
  id: number,
  type: StoryType,
  title: string,
  image: string,
}

export interface StoryResponse {
  id: number;
  title: string;
  subTitle: string;
  type: string;
  sticky: 0 | 1;
  authorName: string;
  authorUsername: string;
  authorImage: string;
  authorURL: string;
  duration: number;
  eventOver: number;
  url: string;
  videoAspectRatio: string;
  image: string;
  body: string;
  // sufficient for now unless we need to access a field in the metatag
  metatags: { [key: string]: string };
  address: string;
  follow_on_content_date: number;
  follow_on_content: string;
  relatedFoodmarks: string[];
  standfirst: string;
  localStandfirst: string;
  availableTranslations: { [key: string]: string };
}

export type ContentListResponse = (ContentListItem | ContentListItemMenu)[]

export interface ContentListItem {
  id: number;
  title: string;
  alias: string;
  menuName: "";
  menuWeight: "";
  externalURL?: string;
}

export interface ContentListItemMenu {
  id: number;
  title: string;
  alias: string;
  menuName: "Footer";
  menuWeight: number;
  externalURL?: string;
}

export const isContentListItemMenu = (isMenuItem: ContentListItem | ContentListItemMenu): isMenuItem is ContentListItemMenu => {
  return isMenuItem.menuName === "Footer"
}

export interface ContentResponse {
  id: number;
  title: string;
  body?: string;
  alias: string;
  urls: LanguageURLs;
  metatags: { [key: string]: string };
  availableTranslations: { [key: string]: string };
}

export interface Pin {
  id: number;
  coordinates: [number, number]
  type: string,
  alias: string,
  tier: number,
  title: string;
  subTitle: string;
  image: string;
  imageLarge: string;
  navImage: string;
  sticky: 0 | 1;
  marker_image?: string;
  homepage: 0 | 1;
}

export type Coordinates = {
  lat: number;
  lng: number;
}

export interface PinGeoJsonProperties {
  id: number,
  alias: string,
  title: string,
  rawTitle?: string;
  image: string,
  marker_image?: string
}

export interface PinGeoJsonFeature {
  type: 'Feature';
  alias?: string;
  geometry: {
    type: 'Point';
    coordinates: [number, number];
  };
  properties: PinGeoJsonProperties
}

export type ContentSuggestion = {
  id: number;
  langcode: string;
  location: [number, number];
  nid: number;
  title: string;
  uuid: string;
}

export interface Language {
  id: string;
  name: string;
  urlPrefix: string;
  nativeName: string;
  direction: string;
  isDefault: boolean;
  urlTypePrefixes: { [key: string]: string };
}

export interface LanguageURLs {
  [key: string]: string;
}

export enum SocialButtonType {
  X = 'X',
  FACEBOOK = 'Facebook',
  WHATSAPP = 'WhatsApp',
  SHAREAPI = 'Share',
}

export enum PinSize {
  Small,
  Medium,
  Large
}

export enum AuthorSize {
  Small,
  Large
}

export type Image = {
  url: string;
  image: HTMLImageElement | ImageBitmap | undefined;
}
