<template>
  <div v-if="!isMobile || mobileSlideoutStore.ready" class="space-y-6 -mt-8" :class="{'pt-6 lg:mt-6': !showCoverImage && !isTier1Event, 'lg:mt-0': showCoverImage || isTier1Event}" :data-nid="content?.id">
    <template v-if="ready && content">
      <SlideoutCoverImage v-if="showCoverImage" :image="content.image" :title="content?.title" :subTitle="content.subTitle" />
      <FoomarkImageNavigation v-else :id="content.id" />

      <div class="prose space-y-6 px-[1.88rem] relative">
        <template v-if="ready && content">
          <AuthorInfo v-if="!content.image && (content.authorName || content.authorUsername || content.authorImage) && !followOnContent" :recommendation="content.type === 'location'" :size="AuthorSize.Large"
                      :name="content.authorName ?? 'anonymous'" :username="content.authorUsername" :image="content.authorImage" :URL="content.authorURL" />
          <h1 v-if="!showCoverImage" v-html="content.title" class="mt-0" />

          <div class="relative min-h-[34px]">
            <AuthorInfo :recommendation="content.type === 'location'" v-if="content.image && (content.authorName || content.authorUsername || content.authorImage) && !followOnContent" :size="AuthorSize.Small"
              :name="content.authorName ?? 'anonymous'" :username="content.authorUsername" :image="content.authorImage" :URL="content.authorURL" />

            <div v-if="!content.image && content.subTitle && content.subTitle != content.body" v-html="content.subTitle" />

            <div class="absolute top-0 right-0 flex gap-3">
              <div class="inline-flex flex-grow items-center group">
                <button class="rounded-full bg-mid-grey w-[34px] h-[34px] flex items-center justify-center group-hover:bg-coca-cola-red group-hover:text-white">
                  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="group-hover:brightness-0 group-hover:invert">
                    <path d="M15 14.12C14.24 14.12 13.56 14.42 13.04 14.89L5.91 10.74C5.96 10.51 6 10.28 6 10.04C6 9.80004 5.96 9.57004 5.91 9.34004L12.96 5.23004C13.5 5.73004 14.21 6.04004 15 6.04004C16.66 6.04004 18 4.70004 18 3.04004C18 1.38004 16.66 0.0400391 15 0.0400391C13.34 0.0400391 12 1.38004 12 3.04004C12 3.28004 12.04 3.51004 12.09 3.74004L5.04 7.85004C4.5 7.35004 3.79 7.04004 3 7.04004C1.34 7.04004 0 8.38004 0 10.04C0 11.7 1.34 13.04 3 13.04C3.79 13.04 4.5 12.73 5.04 12.23L12.16 16.39C12.11 16.6 12.08 16.82 12.08 17.04C12.08 18.65 13.39 19.96 15 19.96C16.61 19.96 17.92 18.65 17.92 17.04C17.92 15.43 16.61 14.12 15 14.12Z" fill="#F40000"/>
                  </svg>
                  <span class="sr-only"   v-html="appStringsStore.get('sharePage')" />
                </button>
                <div class="absolute top-10 left-0 ml-0 flex-col gap-2 hidden group-hover:flex -mt-3 pt-3">
                  <SocialButton :content="content" :type="SocialButtonType.X"></SocialButton>
                  <SocialButton :content="content" :type="SocialButtonType.FACEBOOK"></SocialButton>
                  <SocialButton :content="content" :type="SocialButtonType.WHATSAPP"></SocialButton>
                  <SocialButton :content="content" :type="SocialButtonType.SHAREAPI"></SocialButton>
                </div>
              </div>
              <button @click="toggleStoryFavourite()" :awria-label="appStringsStore.get('favouritesAdd')" :title="appStringsStore.get('favouritesAdd')"
                      class="rounded-full w-[34px] h-[34px] flex items-center justify-center lg:hover:bg-coca-cola-red"
                      :class="{
                'bg-mid-grey': !isFavourite,
                'bg-coca-cola-red': isFavourite,
              }">
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.35011 2.55287C5.21038 -0.879303 0 1.8199 0 6.56676C0 10.0164 2.99906 12.4771 5.24977 14.327C6.80838 15.6068 7.62426 16.2176 8.20944 16.7178C8.66521 17.096 9.32916 17.096 9.77931 16.7062C10.3645 16.2001 11.1691 15.5835 12.7502 14.327C15.0347 12.512 18 9.97567 18 6.52604C18 1.81408 12.7896 -0.88512 9.65552 2.54705C9.25602 2.98334 8.74398 2.98334 8.34448 2.54705L8.35011 2.55287Z"
                    transform="translate(8.5 8.5)" fill="white" />
                </svg>
              </button>
            </div>

            <h2 v-if="content.standfirst || content.localStandfirst" class="font-serif not-prose text-[2rem] leading-[1.4em] my-6 pr-28">
              <span v-if="content.standfirst" v-html="content.standfirst" />
              <span v-if="content.localStandfirst" v-html="content.localStandfirst" />
            </h2>
          </div>

          <address v-if="content.address" v-html="content.address" class="not-italic" />

          <div v-if="followOnContent" v-html="content.follow_on_content" class="prose" />
          <div v-else-if="content.body" v-html="content.body" class="prose" />

          <p v-if="showReadMore && content.url" class="mt-6">
            <a :href="content.url" :alt="appStringsStore.get('articleReadMore')" target="_blank" rel="noopener noreferrer"
              class="btn text-white no-underline uppercase font-sansTitle w-full"
              v-html="appStringsStore.get('articleReadMore')" />
          </p>
        </template>
      </div>
    </template>

    <div v-else-if="!isMobile">
      <div v-if="isTier1Event" class="bg-light-grey-alt animate-pulse w-full aspect-[4/3]"/>
      <FoomarkImageNavigation v-else :id="false" />
      <div class="mt-10 px-10">
        <ContentLoading />
        <h2 class="border-b-[3px] mb-3 mt-14 border-timeout-red text-base-grey text-2xl font-serif leading-[1.25em]"
            v-html="appStringsStore.get('locationBasedResultsTitle')"></h2>
        <StoryCollection :stories="false" />
      </div>
    </div>

    <div v-if="ready && content && !followOnContent">
      <InstagramEmbed v-if="content.type == 'instagram' && content.url" :url="content.url" />
      <TikTokEmbed v-if="content.type == 'tiktok' && content.url" :url="content.url" />
      <VimeoEmbed v-if="(content.type == 'event' || content.type == 'vimeo') && content.url" :url="content.url" :aspectRatio="content.videoAspectRatio" />
      <TasteMakers v-if="content.type == 'event' && tastemakers.length" :stories="tastemakers" />
    </div>

    <RouterLink :to="closeLocation ? closeLocation : { name: `home:${languagesStore.getActiveLanguage().id}` }"
      :aria-label="appStringsStore.get('closePage')"
      :title="appStringsStore.get('closePage')"
      class="rounded-full p-1.5 fixed top-6 right-7 !mt-0 mb-0 shadow z-20 duration-150 transition-colors"
      :class="{'bg-[var(--closeButtonDynamicBackground)] text-[var(--closeButtonDynamicText)]': content?.image, 'bg-base-grey text-white': !content?.image}"
      @click="pinsStore.clearPinSelected()">
      <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </RouterLink>

    <div v-if="relatedPins.length" class="pt-9 px-[1.88rem]">
      <h2 class="border-b-[3px] mb-1 border-timeout-red font-serif text-2xl leading-[1.25em]"
          v-html="appStringsStore.get('readMore')"></h2>
      <RelatedCollection :stories="relatedPins" />
    </div>

    <div class="space-y-6 py-9 px-[1.88rem]" v-if="nearbyPins?.length">
      <div v-for="(teasers, index) in nearbyPins" :key="index" class="my-7">
        <template v-if="teasers.length">
          <h2 class="border-b-[3px] mb-3 border-timeout-red text-base-grey text-2xl font-serif leading-[1.25em]"
              v-html="appStringsStore.get('locationBasedResultsTitle')"></h2>
          <StoryCollection :stories="teasers" />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AuthorInfo from '@/components/AuthorInfo.vue'
import SocialButton from '@/components/SocialButton.vue';
import InstagramEmbed from "@/components/InstagramEmbed.vue";
import TikTokEmbed from "@/components/TikTokEmbed.vue";
import VimeoEmbed from "@/components/VimeoEmbed.vue";
import SlideoutCoverImage from '@/components/SlideoutCoverImage.vue';
import { useRoute, useRouter } from 'vue-router'
import { ref, watch, watchEffect } from 'vue'
import { useMapStore } from "@/stores/map";
import { usePinsStore } from "@/stores/pins";
import { useFetch, useStorage } from '@vueuse/core'
import { AuthorSize, type UseFetchResponse, type Pin, SocialButtonType, type StoryResponse } from '@/types'
import TasteMakers from "@/components/TasteMakers.vue";
import { isDistanceLessThanRange } from "@/utils/haversine";
import { Endpoint, useApiUrl } from '@/composables/useApiUrl';
import { useAppStateStore } from "@/stores/appState";
import ContentLoading from "@/components/ContentLoading.vue";
import { useLanguagesStore } from '@/stores/languages';
import { useAppStringsStore } from '@/stores/appStrings'
import RelatedCollection from "@/components/RelatedCollection.vue";
import * as Fathom from 'fathom-client';
import { useMobileSlideoutStore } from "@/stores/mobileSlideout";
import use404Redirect from "@/composables/use404Redirect";
import useTeaserGroups from "@/composables/useTeaserGroups";
import StoryCollection from "@/components/StoryCollection.vue";
import FoomarkImageNavigation from "@/components/FoomarkImageNavigation.vue";
import useIsMobile from "@/composables/useIsMobile";

const appStringsStore = useAppStringsStore()
const appStateStore = useAppStateStore()
const languagesStore = useLanguagesStore()
const route = useRoute()
const content = ref<StoryResponse>()
const ready = ref(false)
const showCoverImage = ref(false)
const mapStore = useMapStore()
const pinsStore = usePinsStore()
const fetchURL = ref('')
const tastemakers = ref<Pin[]>([])
const followOnContent = ref(false)
const relatedPins = ref<Pin[]>([])
const showReadMore = ref(false)
const mobileSlideoutStore = useMobileSlideoutStore()
const isFavourite = ref(false)
const nearbyPins = ref<Pin[][]>()
const isTier1Event = ref(false)
const isMobile = useIsMobile()

const closeLocation = ref('')
const router = useRouter()

// Load the story content.
const { data: storyContent, isFinished: storyLoaded, execute: fetchData }: UseFetchResponse<[StoryResponse]> =
  useFetch<[StoryResponse]>(fetchURL, {
    async beforeFetch() {
      ready.value = false
    },
    immediate: false
  }).get().json()

const updateStoryContent = () => {
  if (!pinsStore.activePin) return
  console.log('LOAD STORY', pinsStore.activePin.id)
  isTier1Event.value = pinsStore.activePin?.tier === 1 && pinsStore.activePin?.type === 'event'
  showCoverImage.value = false
  fetchURL.value = useApiUrl(Endpoint.GeoPins, { additionalPath: pinsStore.activePin.id.toString() })
  fetchData()

  // If this is not the first page, set the closeLocation to the previous page.
  if (appStateStore.pageCount > 1 && pinsStore.activePin.tier == 1 && pinsStore.activePin.type !== 'event') {
    const previousPage = router.options.history.state.back?.toString()
    closeLocation.value = previousPage ?? ''
  }
  else {
    closeLocation.value = ''
  }
}

const favouritesStorage = useStorage('foodmarksFavourites', new Set<string>())

const toggleStoryFavourite = () => {
  if (!pinsStore.activePin) return
  const pinID = pinsStore.activePin.id.toString()
  if (favouritesStorage.value?.has(pinID)) {
    favouritesStorage.value.delete(pinID)
    console.log('REMOVING FROM FAVORITES', pinsStore.activePin?.id)
    isFavourite.value = false
    Fathom.trackEvent(`Foodmark Un-Favorited: ${pinID}`)
  } else {
    favouritesStorage.value.add(pinID)
    console.log('ADDING TO FAVORITES', pinsStore.activePin?.id)
    isFavourite.value = true
    Fathom.trackEvent(`Foodmark Favorited: ${pinID}`)
  }
}

const loadContent = (flyToPin: boolean = true) => {
  // Set the activePin based on the alias.
  const current_alias = languagesStore.removeURLPrefix('/' + route.params.alias, languagesStore.getActiveLanguage().urlPrefix)
  let pin = [...pinsStore.pinsMap.values()].find((pin: Pin) => pin.alias === current_alias)
  if (pin) {
    pinsStore.newPinSelected(pin.id)
    if (flyToPin) {
      if (pin.type !== 'article') mapStore.flyToPin(pin, {offset: [0, isMobile.value ? -1*(window.innerHeight/2-window.innerHeight/3)-15 : 0]})
      else mapStore.reset()
    }
    updateStoryContent()
  }
  else if (pinsStore.pinsMap.size) {
    use404Redirect(current_alias, 'stories')
  }
}

// Initial Story load (pins loaded whilst on this page).
watch(() => pinsStore.pinsFeatures, () => {
  console.log('NAVIGATION', 'Loaded on a story.')
  loadContent()
}, { deep: true })

// Changed from 1 story to another (Route changed without reloading View).
watch(() => route.path, () => {
  console.log('NAVIGATION', 'Moved from one story to another.')
  loadContent(false)
})

// Changed from a non-story to a story (pins already loaded).
if (pinsStore.pinsFeatures.length) {
  console.log('NAVIGATION', 'Moved from a non-story to a story.')
  loadContent(false)
}

// When the pin content changes, fly to the pin and record it in their history.
watch(() => content.value, () => {
  if (!content.value) return

  mobileSlideoutStore.scrollTop()
  window.scrollTo(0, 0);

  content.value.title = pinsStore.activePin?.title ?? ''
  console.log('NEW TITLE', content.value)
  // Set the active pin if it is different.
  if (pinsStore.activePin?.id !== content.value.id) {
    console.log('new pin selected')
    pinsStore.newPinSelected(content.value.id)
  }

  if (!pinsStore.activePin) return

  // Pulse the marker if it's not custom.
  if (pinsStore.activePin.marker_image === '/src/assets/images/pin.png' || pinsStore.activePin.marker_image?.startsWith('data:image')) mapStore.setPulsingMarker(pinsStore.activePin)

  // Add this view to the history queue.
  let foodmarksViewedQueue: String[] = localStorage.getItem('foodmarksViewedQueue')?.split(',') || []
  let viewed = `${Date.now()}:${content.value.id}`
  foodmarksViewedQueue.push(viewed)
  localStorage.setItem('foodmarksViewedQueue', foodmarksViewedQueue.join(','))

  isFavourite.value = favouritesStorage.value?.has(pinsStore.activePin.id.toString())

  // Add the Tastemakers if we're looking at a tier 1 event.
  const currentPin = pinsStore.activePin
  if (currentPin && content.value.type === 'event' && pinsStore.activePin.tier === 1) {
    // Get all Tastemakers within 1km of the event if the event is not over.
    if (!content.value.eventOver) {
      tastemakers.value = [...pinsStore.pinsMap.values()].filter((pin: Pin) => pin.type !== 'event' && pin.tier === 1 && isDistanceLessThanRange(
        { lat: pin.coordinates[1], lng: pin.coordinates[0] },
        { lat: currentPin.coordinates[1], lng: currentPin.coordinates[0] },
        1))

      console.log('TASTEMAKERS', tastemakers.value)
    }
  }

  // Show a collection of nearby Foodmarks on non-articles.
  if (currentPin && content.value.type !== 'article') {
    const pinsByDistance = pinsStore.sortPinsByProximity(Array.from(pinsStore.pinsMap.values()), pinsStore.activePin.coordinates)
    pinsByDistance.splice(pinsByDistance.findIndex((pin: Pin) => pin.id === currentPin.id), 1)
    const groupedTeasers = useTeaserGroups(pinsByDistance)
    if (groupedTeasers?.[0]) groupedTeasers[0] = [];
    nearbyPins.value = groupedTeasers
    console.log('NEARBY PINS', nearbyPins.value)
  }

  // Tastemakers close button should go back to the event.
  if (currentPin.type !== 'event' && currentPin.tier === 1 && mapStore.closeTier1Pin) {
    closeLocation.value = mapStore.closeTier1Pin.alias
  }

  showReadMore.value = ['content', 'article', 'location'].includes(content.value?.type ?? 'content')

  if (content.value.type !== 'article') mapStore.flyToPinId(content.value.id, {offset: [0, isMobile.value ? -1*(window.innerHeight/2-window.innerHeight/3)-15 : 0]})
  else mapStore.reset()
})

watchEffect(() => {
  if (storyLoaded.value) {
    if (storyContent.value) {
      console.log('CONTENT LOADED', storyContent.value)
      mobileSlideoutStore.scrollTop()

      if (!storyContent.value[0]) return
      content.value = storyContent.value[0]

      showCoverImage.value = (content.value?.image && ['event', 'article'].includes(content.value.type)) as boolean

      if (route.name) {
        appStateStore.setHrefLangTags(content.value?.availableTranslations, route.name.toString(), router)
      }

      // Get pins from pinStore where the ID is in the relatedFoodmarks array.
      const relatedFoodmarks = content.value?.relatedFoodmarks ?? []
      const filteredPins = [...pinsStore.pinsMap.values()].filter((pin: Pin) => relatedFoodmarks.includes(pin.id.toString()))

      // Order the related pins by the order they are in the relatedFoodmarks array.
      filteredPins.sort((a: Pin, b: Pin) => {
        return relatedFoodmarks.indexOf(a.id.toString()) - relatedFoodmarks.indexOf(b.id.toString())
      })

      console.log('RELATED PINS', filteredPins)
      relatedPins.value = filteredPins ?? []

      const now = Math.floor(Date.now() / 1000)
      followOnContent.value = content.value.follow_on_content_date
      && content.value.follow_on_content
      && content.value.follow_on_content_date < now
        ? true : false

      // Set the Meta tags.
      if (content.value.metatags) {
        appStateStore.setMetaTags(content.value.metatags)
      }
    }

    ready.value = true
  }
})
</script>

