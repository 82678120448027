<template>
  <footer class="px-[1.88rem] pb-12 pt-5">
    <div class="flex space-x-5">
      <div class="w-1/2 flex flex-col space-y-3 text-sm">
        <h3 class="border-timeout-red border-b-[3px] text-xl font-serif uppercase break-words">{{ appStringsStore.get('footerNav1') }}</h3>
        <RouterLink v-if="languagesStore.getActiveLanguage()" class="hover:underline" :to="{ name: `favourites:${languagesStore.getActiveLanguage().id}` }">
          <span v-html="appStringsStore.get('favouritesHeader')"></span>
        </RouterLink>
        <template v-if="footerPages && languagesStore.getActiveLanguage()">
          <template v-for="(page, key) in footerPages" :key="key">
            <a v-if="page.externalURL" :href="page.externalURL" class="hover:underline" target="_blank">
              <span v-html="page.title" />
            </a>
            <RouterLink v-else class="hover:underline" :to="{
              name: `content:${languagesStore.getActiveLanguage().id}`,
              params: {
                alias: page.alias
              }
            }">
              <span v-html="page.title" />
            </RouterLink>
          </template>
        <span class="text-sm">Made with <img class="inline" alt="mapbox" src="/images/mapbox.svg" width="80" /></span>
        </template>
      </div>
      <div class="w-1/2 flex flex-col space-y-3">
        <h3 class="flex items-center border-timeout-red border-b-[3px] space-x-3 break-words">
          <img :src="languageIcon" width="24" height="24" alt="language icon" />
          <div class="text-xl font-serif uppercase">{{ appStringsStore.get('footerNav2') }}</div>
        </h3>
        <template v-if="languagesStore.languages.length">
        <div class="flex items-center space-x-2 text-sm" v-for="language in languagesStore.languages" :key="language.id">
          <div :style="{
            'background-color': language.id === languagesStore.getActiveLanguage()?.id ? '#D30000' : 'transparent'
          }" class="rounded-full w-2 h-2 bg-timeout-red"></div>
          <a :href="'/' + languagesStore.getLanguageURLPrefix(language)" @click.prevent="languageClicked('/' + languagesStore.getLanguageURLPrefix(language), language.id)" class="hover:underline">
            {{ language.nativeName }}
          </a>
        </div>
        </template>
      </div>
    </div>

    <div class=" mt-12 pt-6 text-sm border-timeout-red border-t-[3px]" v-html="appStringsStore.get('footerSmallPrint').replace('[YEAR]', year)" />
  </footer>
</template>

<script setup lang="ts">
import { useLanguagesStore } from '@/stores/languages'
import languageIcon from '@/assets/images/languageIcon.svg'
import { useAppStringsStore } from "@/stores/appStrings";
import { useGeneralPagesStore } from "@/stores/generalPages";
import { watch, ref } from "vue";
import { isContentListItemMenu, type ContentListResponse, type ContentListItemMenu } from "@/types";

const languagesStore = useLanguagesStore()
const appStringsStore = useAppStringsStore()
const generalPages = useGeneralPagesStore()
const footerPages = ref<ContentListResponse>()
const year = new Date().getFullYear().toString()

const remoteURLMappings: any = {
  'privacy-notice': 'https://www.timeout.com/privacy-notice',
  'editorial-guidelines': 'https://www.timeout.com/about/editorial-guidelines',
  'do-not-sell-my-information': 'https://www.timeout.com/privacy-notice#CCPA',
  'cookie-policy': 'https://www.timeout.com/cookie-policy',
  'accessibility-statement': 'https://www.timeout.com/accessibility-statement',
  'terms-of-use': 'https://www.timeout.com/terms-of-use',
}

watch(() =>  generalPages.pages, () => {
  if (!generalPages.pages) return

  // Get an array of the general pages that have a menuName of "Footer".
  const allGeneralPages: ContentListItemMenu[] = generalPages.pages.filter(isContentListItemMenu)

  // Sort the footerPages by their menuWeight.
  allGeneralPages.sort((a, b) => a.menuWeight - b.menuWeight)

  // Add the externalURL to the footerPages if it exists in the remoteURLMappings.
  allGeneralPages.forEach((page) => {
    // If the alias ends with the remoteURLMapping, then set the externalURL.
    Object.keys(remoteURLMappings).forEach((remoteURLMapping) => {
      if (page.alias.endsWith(remoteURLMapping)) {
        page.externalURL = remoteURLMappings[remoteURLMapping]
      }
    })
  })

  console.log('FOOTER MENU', allGeneralPages)

  footerPages.value = allGeneralPages
}, { deep: true, immediate: true })

const languageClicked = (href: string, language: string) => {
  // Set the local store as this was an intentional change.
  localStorage.setItem('languageManuallySet', language)
  window.location.href = href;
}
</script>
