<template>
  <div class="space-y-6 py-9 px-[1.88rem]">
    <template v-if="ready">
      <div class="prose">
        <h1 v-html="appStringsStore.get('locationBasedResultsTitle')" class="font-serif font-normal mb-6" />
        <p v-html="appStringsStore.get('locationBasedResultsBody')"></p>
      </div>
      <div v-for="(teasers, index) in teasersByType" :key="index" class="my-7">
        <template v-if="teasers.length">
          <h2 class="border-b-[3px] mb-3 border-timeout-red text-base-grey text-2xl font-serif leading-[1.25em]"
              v-html="appStringsStore.get('tier' + (teasers[0]?.tier == 1 ? 1 : 2) + 'Title')"></h2>
          <FeaturedCollection v-if="teasers[0]?.tier == 1" :stories="teasers" />
          <StoryCollection v-else :stories="teasers" />
        </template>
      </div>
    </template>
    <template v-else>
      <h1>Loading...</h1>
    </template>
    <RouterLink :to="{ name: `home:${languageStore.getActiveLanguage().id}` }" class="rounded-full bg-base-grey text-white p-1.5 absolute top-4 right-4 m-2 close-button" :aria-label="appStringsStore.get('closePage')" :title="appStringsStore.get('closePage')">
      <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2"
           stroke-linecap="round" stroke-linejoin="round">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { usePinsStore } from '@/stores/pins'
import { useAppStateStore } from '@/stores/appState'
import StoryCollection from "@/components/StoryCollection.vue";
import useTeaserGroups from "@/composables/useTeaserGroups";
import { useAppStringsStore } from '@/stores/appStrings'
import { type Pin, type Coordinates } from '@/types'
import { coords2geojson, geojson2coords, haversineDistance, isDistanceLessThanRange } from "@/utils/haversine";
import { useRoute, useRouter } from "vue-router";
import { useMapStore } from "@/stores/map";
import { useLanguagesStore } from "@/stores/languages";
import FeaturedCollection from "@/components/FeaturedCollection.vue";
import { useMobileSlideoutStore } from "@/stores/mobileSlideout";
import useIsMobile from "@/composables/useIsMobile";

const appStateStore = useAppStateStore()
const mapStateStore = useMapStore()
const pinsStore = usePinsStore()
const ready = ref(false)
const teasersByType = ref<Pin[][]>()
const appStringsStore = useAppStringsStore()
const route = useRoute()
const latLong = ref<Coordinates>()
const languageStore = useLanguagesStore()
const router = useRouter()
const mobileSlideoutStore = useMobileSlideoutStore()
const isMobile = useIsMobile()

const updateTeasers = () => {
  const hash = route.hash
  const hashLatLng = hash.replace('#', '').split(',').map((x) => parseFloat(x))
  if (hashLatLng.length !== 2) {
    router.push({ name: `home:${languageStore.getActiveLanguage().id}` })
    return
  }

  const userCoords = {lat: hashLatLng[0], lng: hashLatLng[1]} as Coordinates
  latLong.value = userCoords

  if (userCoords) {
    const pinsByDistance = pinsStore.sortPinsByProximity(Array.from(pinsStore.pinsMap.values()), coords2geojson(latLong.value))
    const sortedGroupedTeasers = useTeaserGroups(pinsByDistance)

    // Limit tier 1 events to 300km radius.
    if (sortedGroupedTeasers?.[0]) {
      sortedGroupedTeasers[0] = sortedGroupedTeasers[0].filter((pin: Pin) => pin.type === 'event' && pin.tier === 1 && isDistanceLessThanRange(
        geojson2coords(pin.coordinates),
        userCoords,
        200))
    }
    teasersByType.value = sortedGroupedTeasers
    mobileSlideoutStore.scrollTop()
    ready.value = true
  }
  else {
    router.push({ name: `home:${languageStore.getActiveLanguage().id}` })
  }
}

const calculateZoomLevel = () => {
  if (!latLong.value) return 12

  // Calculate the zoom level required to show the nearest pin with latLong centred.
  const pinsByDistance = pinsStore.sortPinsByProximity(Array.from(pinsStore.pinsMap.values()), coords2geojson(latLong.value))
  const nearestPin = pinsByDistance[0]
  const distance = haversineDistance(
    latLong.value,
    geojson2coords(nearestPin.coordinates)
  )

  // Calculating the zoom level is hard because it depends on the screen size
  // and the distance to the nearest pin. We can't measure because we need to
  // know before zooming.

  const zoom = (distance < 3) ? 13
    : distance < 7.5 ? isMobile.value ? 10 : 12
    : distance < 12.5 ? isMobile.value ? 9 : 11
    : distance < 25 ? isMobile.value ? 8 : 10
    : distance < 30 ? isMobile.value ? 8 : 9.5
    : distance < 50 ? isMobile.value ? 7 : 9
    : distance < 75 ? isMobile.value ? 6.5 : 8.5
    : distance < 90 ? isMobile.value ? 6.5 : 8
    : distance < 100 ? isMobile.value ? 6.5 : 7.5
    : distance < 200 ? isMobile.value ? 5.5 : 7
    : distance < 300 ? isMobile.value ? 5 : 6
    : distance < 400 ? isMobile.value ? 4 : 5.5
    : distance < 500 ? isMobile.value ? 4 : 5
    : distance < 1000 ? isMobile.value ? 4 : 3
    : isMobile.value ? 1 : 2

  return zoom
}

const flyToUser = (zoom: boolean = true) => {
  let suggestedZoom = mapStateStore.map?.getZoom()

  if (zoom) {
    suggestedZoom = calculateZoomLevel()
  }

  if (haversineDistance(latLong.value as Coordinates, mapStateStore.map?.getCenter() as Coordinates) > 0.01) {
    console.log('FLY TO USER', zoom, latLong.value, suggestedZoom)
    if (latLong.value) mapStateStore.flyTo(coords2geojson(latLong.value), {zoom: suggestedZoom})
  }
  mapStateStore.userLocationRequested = false
}

// Changed from 1 Location to another.
watch(() => route.hash, () => {
  updateTeasers()
  console.log('NAVIGATION', 'Moved from one location to another.', latLong.value)
  flyToUser(mapStateStore.userLocationRequested)
})

// Started on a location URL.
watch(() => appStateStore.pinsReady, () => {
  if (!appStateStore.pinsReady) return;
  updateTeasers()
  console.log('NAVIGATION', 'Starting on location.', latLong.value)
  flyToUser()
})

// Moved from a non-location to a location.
if (appStateStore.pinsReady) {
  updateTeasers()
  console.log('NAVIGATION', 'Moved from a non-location to a location.', latLong.value)
  flyToUser()
}
</script>
