import { watchEffect, ref } from 'vue';
import { Endpoint, useApiUrl } from '@/composables/useApiUrl';
import { useFetch } from "@vueuse/core"
import { useLanguagesStore } from "@/stores/languages";
import { useAppStateStore } from "@/stores/appState";

export default function use404Redirect(alias: string, type: string) {
  // Fetch all the redirects.
  const apiUrl = useApiUrl(Endpoint.Redirects)
  const { data: redirectContent, isFinished } = useFetch(apiUrl).get().json()
  alias = '/' + alias
  const languagesStore = useLanguagesStore()

  const goto404 = () => {
    console.log('REDIRECTING TO 404')
    const prefix = languagesStore.getLanguageURLPrefix(languagesStore.getActiveLanguage())
    window.location.href = '/' + prefix + (prefix.length ? '/' : '') + '404'
  }

  watchEffect(() => {
    if (isFinished.value && redirectContent.value) {
      console.log('REDIRECTS', alias, redirectContent.value)

      // Check if url is a key in the redirects object. If so, redirect to the value.
      if (Object.prototype.hasOwnProperty.call(redirectContent.value, alias)) {
        console.log('REDIRECT MATCH', alias, redirectContent.value[alias])

        let destination = redirectContent.value[alias]
        // If the destination is a relative path, add the current language's prefix etc.
        if (!destination.includes('://')) {
          destination = languagesStore.removeURLPrefix(redirectContent.value[alias], languagesStore.getCurrentLanguageURLPrefix())
          const langPrefix = languagesStore.getCurrentLanguageURLPrefix()
          const typePrefix = ref('')

          // Get the type prefix for the current language.
          if (languagesStore.getActiveLanguage().urlTypePrefixes[`${type}URLPrefix`]) {
            typePrefix.value = encodeURI(languagesStore.getActiveLanguage().urlTypePrefixes[`${type}URLPrefix`])
          }
          // If the current language doesn't have a type prefix, use the default language's type prefix.
          else if (languagesStore.getDefaultLanguage().urlTypePrefixes[`${type}URLPrefix`]) {
            typePrefix.value = encodeURI(languagesStore.getDefaultLanguage().urlTypePrefixes[`${type}URLPrefix`])
          }
          // Otherwise, send the user to a 404.
          else {
            return goto404()
          }
          destination = (langPrefix ? '/' + langPrefix : '') + '/' + typePrefix.value + '/' + destination
        }

        console.log('REDIRECTING TO', destination)
        const appStateStore = useAppStateStore()
        // Perform the redirect via meta tags so Google sees it as a 301 (even though network shows 200).
        appStateStore.setMetaTags({'refresh': `0;url=${destination}`})
      }
      // Otherwise send the user to the 404 page.
      else {
        goto404()
      }
    }
  })
}
