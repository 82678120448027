<script setup lang="ts">

</script>

<template>
  <div class="flex items-center justify-center p-14">
    <div class="p-14 shadow-2xl rounded-2xl w-2/3 prose">
      <h1 class="text-4xl mb-4">Fonts test</h1>

      <p>Chat GPT was asked for the most commonly used fonts in each language.</p>

      <h2 class="font-TCCCUnity text-3xl font-normal text-coca-cola-red">TCCC Unity</h2>
      <div class="font-TCCCUnity text-xl">
        <h3 class="font-sans text-xl">Numbers and symbols <span class="text-[#458600]">(77/77)</span></h3>
        <p>0 1 2 3 4 5 6 7 8 9 . , : ; ? ! " ' ( ) [ ] + - × ÷ = $ € £ & @ % # _ ´ ` ¨ ¸</p>

        <h3 class="font-sans text-xl">Polish <span class="text-[#458600]">(78/78)</span></h3>
        <p>A Ą B C Ć D E Ę F G H I J K L Ł M N Ń O Ó P R S Ś T U W Y Z Ź Ż ć ę ł ń ó ś ź ż</p>

        <h3 class="font-sans text-xl">German <span class="text-[#458600]">(58/58)</span></h3>
        <p>A Ä B C D E F G H I J K L M N O Ö P Q R S T U Ü V W X Y Z ß</p>

        <h3 class="font-sans text-xl">Italian <span class="text-[#458600]">(57/57)</span></h3>
        <p>A B C D E F G H I L M N O P Q R S T U V Z A À È É Ì Ò Ó Ù</p>

        <h3 class="font-sans text-xl">English <span class="text-[#458600]">(102/102)</span></h3>
        <p>A B C D E F G H I J K L M N O P Q R S T U V W X Y Z
          a b c d e f g h i j k l m n o p q r s t u v w x y z</p>

        <h3 class="font-sans text-xl">Spanish <span class="text-[#458600]">(130/130)</span></h3>
        <p>A Á B C D E É F G H I Í J K L M N Ñ O Ó P Q R S T U Ú Ü V W X Y Z
          a á b c d e é f g h i í j k l m n ñ o ó p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">Portuguese <span class="text-[#458600]">(153/153)</span></h3>
        <p>A Á À Â Ã B C Ç D E É Ê F G H I Í J K L M N O Ó Ô Õ P Q R S T U Ú Ü V W X Y Z
          a á à â ã b c ç d e é ê f g h i í j k l m n o ó ô õ p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">French <span class="text-[#458600]">(161/161)</span></h3>
        <p>A À Â Æ B C Ç D E É È Ê Ë F G H I Î Ï J K L M N O Ô Œ P Q R S T U Û Ü V W X Y Ÿ Z
          a à â æ b c ç d e é è ê ë f g h i î ï j k l m n o ô œ p q r s t u û ü v w x y ÿ z</p>

        <h3 class="font-sans text-xl">Traditional Chinese <span class="text-timeout-red">(57/117)</span></h3>
        <p>一 二 三 四 五 六 七 八 九 十
          百 千 万 亿 口 目 田 山 木 水
          火 土 金 王 日 月 金 木 水 火
          土 人 大 小 中 上 下 左 右 前
          后 东 西 南 北 天 地 人 王 玉
          米 鸟 鱼 马 羊 牛 狗 猫 龙 风</p>

        <h3 class="font-sans text-xl">Catalan <span class="text-[#458600]">(137/137)</span></h3>
        <p>A À B C Ç D E É È F G H I Í J K L M N O Ó Ò P Q R S T U Ú Ü V W X Y Z
          a à b c ç d e é è f g h i í j k l m n o ó ò p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">Korean <span class="text-timeout-red">(47/97)</span></h3>
        <p>ㄱ ㄴ ㄷ ㄹ ㅁ ㅂ ㅅ ㅇ ㅈ ㅊ ㅋ ㅌ ㅍ ㅎ
          ㅏ ㅑ ㅓ ㅕ ㅗ ㅛ ㅜ ㅠ ㅡ ㅣ
          ㅐ ㅔ ㅒ ㅖ ㅘ ㅙ, ㅚ ㅝ ㅞ ㅟ ㅢ
          가 나 다 라 마 바 사 아 자 차 카 타 파 하</p>

        <h3 class="font-sans text-xl">Thai <span class="text-timeout-red">(63/133)</span></h3>
        <p>ก ข ฃ ค ฅ ฆ ง จ ฉ ช ซ ฌ ญ ฎ ฏ ฐ ฑ ฒ ณ ด ต ถ ท ธ น บ ป ผ ฝ พ ฟ ภ ม ย ร ล ว ศ ษ ส ห ฬ อ ฮ
          ะ ั า ำ ิ ี ึ ื ุ ู โ ใ ไ ฤ ฤๅ ฦ ฦ ่ ้ ๊ ๋ ็ๅ</p>

        <h3 class="font-sans text-xl">Russian <span class="text-timeout-red">(63/130)</span></h3>
        <p>А Б В Г Д Е Ё Ж З И Й К Л М Н О П Р С Т У Ф Х Ц Ч Ш Щ Ъ Ы Ь Э Ю Я
          а б в г д е ё ж з и й к л м н о п р с т у ф х ц ч ш щ ъ ы ь э ю я</p>

          <h3 class="font-sans text-xl">Japanese <span class="text-timeout-red">(152/317)</span></h3>
        <p>あ い う え お か き く け こ さ し す せ そ た ち つ て と な に ぬ ね の は ひ ふ へ ほ ま み む め も や ゆ よ ら り る れ ろ わ を ん
          ア イ ウ エ オ カ キ ク ケ コ サ シ ス セ ソ タ チ ツ テ ト ナ ニ ヌ ネ ノ ハ ヒ フ ヘ ホ マ ミ ム メ モ ヤ ユ ヨ ラ リ ル レ ロ ワ ヲ ン
          人 一 日 本 語 学 生 月 木 金 水 火 土 曜 日 年 今 何 百 千 円 元 外 国 会 社 会 町 都 道 府 県 車 男 女 子 父 母 友 高 校 大 学 小 中 高 美 術 音 楽 科 学 歴 史 地 理 英 語 数 学 体 育 月 火 水 木 金 土</p>

        <h3 class="font-sans text-xl">Turkish <span class="text-[#458600]">(114/114)</span></h3>
        <p>A B C Ç D E F G Ğ H I İ J K L M N O Ö P R S Ş T U Ü V Y Z
          a b c ç d e f g ğ h ı i j k l m n o ö p r s ş t u ü v y z</p>
      </div>

      <h2 class="font-serif text-3xl font-normal text-coca-cola-red">BetterWith</h2>
      <div class="font-serif text-xl">
        <h3 class="font-sans text-xl">Numbers and symbols <span class="text-[#458600]">(77/77)</span></h3>
        <p>0 1 2 3 4 5 6 7 8 9 . , : ; ? ! " ' ( ) [ ] + - × ÷ = $ € £ & @ % # _ ´ ` ¨ ¸</p>

        <h3 class="font-sans text-xl">Polish <span class="text-[#458600]">(78/78)</span></h3>
        <p>A Ą B C Ć D E Ę F G H I J K L Ł M N Ń O Ó P R S Ś T U W Y Z Ź Ż ć ę ł ń ó ś ź ż</p>

        <h3 class="font-sans text-xl">German <span class="text-[#458600]">(58/58)</span></h3>
        <p>A Ä B C D E F G H I J K L M N O Ö P Q R S T U Ü V W X Y Z ß</p>

        <h3 class="font-sans text-xl">Italian <span class="text-[#458600]">(57/57)</span></h3>
        <p>A B C D E F G H I L M N O P Q R S T U V Z A À È É Ì Ò Ó Ù</p>

        <h3 class="font-sans text-xl">English <span class="text-[#458600]">(102/102)</span></h3>
        <p>A B C D E F G H I J K L M N O P Q R S T U V W X Y Z
          a b c d e f g h i j k l m n o p q r s t u v w x y z</p>

        <h3 class="font-sans text-xl">Spanish <span class="text-[#458600]">(130/130)</span></h3>
        <p>A Á B C D E É F G H I Í J K L M N Ñ O Ó P Q R S T U Ú Ü V W X Y Z
          a á b c d e é f g h i í j k l m n ñ o ó p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">Portuguese <span class="text-[#458600]">(153/153)</span></h3>
        <p>A Á À Â Ã B C Ç D E É Ê F G H I Í J K L M N O Ó Ô Õ P Q R S T U Ú Ü V W X Y Z
          a á à â ã b c ç d e é ê f g h i í j k l m n o ó ô õ p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">French <span class="text-[#458600]">(161/161)</span></h3>
        <p>A À Â Æ B C Ç D E É È Ê Ë F G H I Î Ï J K L M N O Ô Œ P Q R S T U Û Ü V W X Y Ÿ Z
          a à â æ b c ç d e é è ê ë f g h i î ï j k l m n o ô œ p q r s t u û ü v w x y ÿ z</p>

        <h3 class="font-sans text-xl">Traditional Chinese <span class="text-timeout-red">(57/117)</span></h3>
        <p>一 二 三 四 五 六 七 八 九 十
          百 千 万 亿 口 目 田 山 木 水
          火 土 金 王 日 月 金 木 水 火
          土 人 大 小 中 上 下 左 右 前
          后 东 西 南 北 天 地 人 王 玉
          米 鸟 鱼 马 羊 牛 狗 猫 龙 风</p>

        <h3 class="font-sans text-xl">Catalan <span class="text-[#458600]">(137/137)</span></h3>
        <p>A À B C Ç D E É È F G H I Í J K L M N O Ó Ò P Q R S T U Ú Ü V W X Y Z
          a à b c ç d e é è f g h i í j k l m n o ó ò p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">Korean <span class="text-timeout-red">(48/97)</span></h3>
        <p>ㄱ ㄴ ㄷ ㄹ ㅁ ㅂ ㅅ ㅇ ㅈ ㅊ ㅋ ㅌ ㅍ ㅎ
          ㅏ ㅑ ㅓ ㅕ ㅗ ㅛ ㅜ ㅠ ㅡ ㅣ
          ㅐ ㅔ ㅒ ㅖ ㅘ ㅙ, ㅚ ㅝ ㅞ ㅟ ㅢ
          가 나 다 라 마 바 사 아 자 차 카 타 파 하</p>

        <h3 class="font-sans text-xl">Thai <span class="text-timeout-red">(51/133)</span></h3>
        <p>ก ข ฃ ค ฅ ฆ ง จ ฉ ช ซ ฌ ญ ฎ ฏ ฐ ฑ ฒ ณ ด ต ถ ท ธ น บ ป ผ ฝ พ ฟ ภ ม ย ร ล ว ศ ษ ส ห ฬ อ ฮ
          ะ ั า ำ ิ ี ึ ื ุ ู โ ใ ไ ฤ ฤๅ ฦ ฦ ่ ้ ๊ ๋ ็ๅ</p>

        <h3 class="font-sans text-xl">Russian <span class="text-timeout-red">(64/130)</span></h3>
        <p>А Б В Г Д Е Ё Ж З И Й К Л М Н О П Р С Т У Ф Х Ц Ч Ш Щ Ъ Ы Ь Э Ю Я
          а б в г д е ё ж з и й к л м н о п р с т у ф х ц ч ш щ ъ ы ь э ю я</p>

        <h3 class="font-sans text-xl">Japanese</h3>
        <p>あ い う え お か き く け こ さ し す せ そ た ち つ て と な に ぬ ね の は ひ ふ へ ほ ま み む め も や ゆ よ ら り る れ ろ わ を ん
          ア イ ウ エ オ カ キ ク ケ コ サ シ ス セ ソ タ チ ツ テ ト ナ ニ ヌ ネ ノ ハ ヒ フ ヘ ホ マ ミ ム メ モ ヤ ユ ヨ ラ リ ル レ ロ ワ ヲ ン
          人 一 日 本 語 学 生 月 木 金 水 火 土 曜 日 年 今 何 百 千 円 元 外 国 会 社 会 町 都 道 府 県 車 男 女 子 父 母 友 高 校 大 学 小 中 高 美 術 音 楽 科 学 歴 史 地 理 英 語 数 学 体 育 月 火 水 木 金 土</p>

        <h3 class="font-sans text-xl">Turkish <span class="text-[#458600]">(114/114)</span></h3>
        <p>A B C Ç D E F G Ğ H I İ J K L M N O Ö P R S Ş T U Ü V Y Z
          a b c ç d e f g ğ h ı i j k l m n o ö p r s ş t u ü v y z</p>
      </div>

      <h2 class="font-sansTitle text-3xl font-normal text-coca-cola-red">LibreFranklin</h2>
      <div class="font-sansTitle text-xl">
        <h3 class="font-sans text-xl">Numbers and symbols <span class="text-[#458600]">(77/77)</span></h3>
        <p>0 1 2 3 4 5 6 7 8 9 . , : ; ? ! " ' ( ) [ ] + - × ÷ = $ € £ & @ % # _ ´ ` ¨ ¸</p>

        <h3 class="font-sans text-xl">Polish <span class="text-[#458600]">(78/78)</span></h3>
        <p>A Ą B C Ć D E Ę F G H I J K L Ł M N Ń O Ó P R S Ś T U W Y Z Ź Ż ć ę ł ń ó ś ź ż</p>

        <h3 class="font-sans text-xl">German <span class="text-[#458600]">(58/58)</span></h3>
        <p>A Ä B C D E F G H I J K L M N O Ö P Q R S T U Ü V W X Y Z ß</p>

        <h3 class="font-sans text-xl">Italian <span class="text-[#458600]">(57/57)</span></h3>
        <p>A B C D E F G H I L M N O P Q R S T U V Z A À È É Ì Ò Ó Ù</p>

        <h3 class="font-sans text-xl">English <span class="text-[#458600]">(102/102)</span></h3>
        <p>A B C D E F G H I J K L M N O P Q R S T U V W X Y Z
          a b c d e f g h i j k l m n o p q r s t u v w x y z</p>

        <h3 class="font-sans text-xl">Spanish <span class="text-[#458600]">(130/130)</span></h3>
        <p>A Á B C D E É F G H I Í J K L M N Ñ O Ó P Q R S T U Ú Ü V W X Y Z
          a á b c d e é f g h i í j k l m n ñ o ó p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">Portuguese <span class="text-[#458600]">(152/152)</span></h3>
        <p>A Á À Â Ã B C Ç D E É Ê F G H I Í J K L M N O Ó Ô Õ P Q R S T U Ú Ü V W X Y Z
          a á à â ã b c ç d e é ê f g h i í j k l m n o ó ô õ p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">French <span class="text-[#458600]">(161/161)</span></h3>
        <p>A À Â Æ B C Ç D E É È Ê Ë F G H I Î Ï J K L M N O Ô Œ P Q R S T U Û Ü V W X Y Ÿ Z
          a à â æ b c ç d e é è ê ë f g h i î ï j k l m n o ô œ p q r s t u û ü v w x y ÿ z</p>

        <h3 class="font-sans text-xl">Traditional Chinese <span class="text-timeout-red">(57/117)</span></h3>
        <p>一 二 三 四 五 六 七 八 九 十
          百 千 万 亿 口 目 田 山 木 水
          火 土 金 王 日 月 金 木 水 火
          土 人 大 小 中 上 下 左 右 前
          后 东 西 南 北 天 地 人 王 玉
          米 鸟 鱼 马 羊 牛 狗 猫 龙 风</p>

        <h3 class="font-sans text-xl">Catalan <span class="text-[#458600]">(137/137)</span></h3>
        <p>A À B C Ç D E É È F G H I Í J K L M N O Ó Ò P Q R S T U Ú Ü V W X Y Z
          a à b c ç d e é è f g h i í j k l m n o ó ò p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">Korean <span class="text-timeout-red">(48/97)</span></h3>
        <p>ㄱ ㄴ ㄷ ㄹ ㅁ ㅂ ㅅ ㅇ ㅈ ㅊ ㅋ ㅌ ㅍ ㅎ
          ㅏ ㅑ ㅓ ㅕ ㅗ ㅛ ㅜ ㅠ ㅡ ㅣ
          ㅐ ㅔ ㅒ ㅖ ㅘ ㅙ, ㅚ ㅝ ㅞ ㅟ ㅢ
          가 나 다 라 마 바 사 아 자 차 카 타 파 하</p>

        <h3 class="font-sans text-xl">Thai <span class="text-timeout-red">(51/133)</span></h3>
        <p>ก ข ฃ ค ฅ ฆ ง จ ฉ ช ซ ฌ ญ ฎ ฏ ฐ ฑ ฒ ณ ด ต ถ ท ธ น บ ป ผ ฝ พ ฟ ภ ม ย ร ล ว ศ ษ ส ห ฬ อ ฮ
          ะ ั า ำ ิ ี ึ ื ุ ู โ ใ ไ ฤ ฤๅ ฦ ฦๅ ่ ้ ๊ ๋ ็</p>

        <h3 class="font-sans text-xl">Russian <span class="text-timeout-red">(64/132)</span></h3>
        <p>А Б В Г Д Е Ё Ж З И Й К Л М Н О П Р С Т У Ф Х Ц Ч Ш Щ Ъ Ы Ь Э Ю Я
          а б в г д е ё ж з и й к л м н о п р с т у ф х ц ч ш щ ъ ы ь э ю я</p>

        <h3 class="font-sans text-xl">Japanese</h3>
        <p>あ い う え お か き く け こ さ し す せ そ た ち つ て と な に ぬ ね の は ひ ふ へ ほ ま み む め も や ゆ よ ら り る れ ろ わ を ん
          ア イ ウ エ オ カ キ ク ケ コ サ シ ス セ ソ タ チ ツ テ ト ナ ニ ヌ ネ ノ ハ ヒ フ ヘ ホ マ ミ ム メ モ ヤ ユ ヨ ラ リ ル レ ロ ワ ヲ ン
          人 一 日 本 語 学 生 月 木 金 水 火 土 曜 日 年 今 何 百 千 円 元 外 国 会 社 会 町 都 道 府 県 車 男 女 子 父 母 友 高 校 大 学 小 中 高 美 術 音 楽 科 学 歴 史 地 理 英 語 数 学 体 育 月 火 水 木 金 土</p>

        <h3 class="font-sans text-xl">Turkish <span class="text-[#458600]">(114/114)</span></h3>
        <p>A B C Ç D E F G Ğ H I İ J K L M N O Ö P R S Ş T U Ü V Y Z
          a b c ç d e f g ğ h ı i j k l m n o ö p r s ş t u ü v y z</p>
      </div>

      <h2 class="font-sans text-3xl font-normal text-coca-cola-red">Roboto</h2>
      <div class="font-sans text-xl">
        <h3 class="font-sans text-xl">Numbers and symbols <span class="text-[#458600]">(77/77)</span></h3>
        <p>0 1 2 3 4 5 6 7 8 9 . , : ; ? ! " ' ( ) [ ] + - × ÷ = $ € £ & @ % # _ ´ ` ¨ ¸</p>

        <h3 class="font-sans text-xl">Polish <span class="text-[#458600]">(78/78)</span></h3>
        <p>A Ą B C Ć D E Ę F G H I J K L Ł M N Ń O Ó P R S Ś T U W Y Z Ź Ż ć ę ł ń ó ś ź ż</p>

        <h3 class="font-sans text-xl">German <span class="text-[#458600]">(58/58)</span></h3>
        <p>A Ä B C D E F G H I J K L M N O Ö P Q R S T U Ü V W X Y Z ß</p>

        <h3 class="font-sans text-xl">Italian <span class="text-[#458600]">(57/57)</span></h3>
        <p>A B C D E F G H I L M N O P Q R S T U V Z A À È É Ì Ò Ó Ù</p>

        <h3 class="font-sans text-xl">English <span class="text-[#458600]">(102/102)</span></h3>
        <p>A B C D E F G H I J K L M N O P Q R S T U V W X Y Z
          a b c d e f g h i j k l m n o p q r s t u v w x y z</p>

        <h3 class="font-sans text-xl">Spanish <span class="text-[#458600]">(130/130)</span></h3>
        <p>A Á B C D E É F G H I Í J K L M N Ñ O Ó P Q R S T U Ú Ü V W X Y Z
          a á b c d e é f g h i í j k l m n ñ o ó p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">Portuguese <span class="text-[#458600]">(152/152)</span></h3>
        <p>A Á À Â Ã B C Ç D E É Ê F G H I Í J K L M N O Ó Ô Õ P Q R S T U Ú Ü V W X Y Z
          a á à â ã b c ç d e é ê f g h i í j k l m n o ó ô õ p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">French <span class="text-[#458600]">(161/161)</span></h3>
        <p>A À Â Æ B C Ç D E É È Ê Ë F G H I Î Ï J K L M N O Ô Œ P Q R S T U Û Ü V W X Y Ÿ Z
          a à â æ b c ç d e é è ê ë f g h i î ï j k l m n o ô œ p q r s t u û ü v w x y ÿ z</p>

        <h3 class="font-sans text-xl">Traditional Chinese <span class="text-timeout-red">(57/117)</span></h3>
        <p>一 二 三 四 五 六 七 八 九 十
          百 千 万 亿 口 目 田 山 木 水
          火 土 金 王 日 月 金 木 水 火
          土 人 大 小 中 上 下 左 右 前
          后 东 西 南 北 天 地 人 王 玉
          米 鸟 鱼 马 羊 牛 狗 猫 龙 风</p>

        <h3 class="font-sans text-xl">Catalan <span class="text-[#458600]">(137/137)</span></h3>
        <p>A À B C Ç D E É È F G H I Í J K L M N O Ó Ò P Q R S T U Ú Ü V W X Y Z
          a à b c ç d e é è f g h i í j k l m n o ó ò p q r s t u ú ü v w x y z</p>

        <h3 class="font-sans text-xl">Korean <span class="text-timeout-red">(48/97)</span></h3>
        <p>ㄱ ㄴ ㄷ ㄹ ㅁ ㅂ ㅅ ㅇ ㅈ ㅊ ㅋ ㅌ ㅍ ㅎ
          ㅏ ㅑ ㅓ ㅕ ㅗ ㅛ ㅜ ㅠ ㅡ ㅣ
          ㅐ ㅔ ㅒ ㅖ ㅘ ㅙ, ㅚ ㅝ ㅞ ㅟ ㅢ
          가 나 다 라 마 바 사 아 자 차 카 타 파 하</p>

        <h3 class="font-sans text-xl">Thai <span class="text-timeout-red">(51/133)</span></h3>
        <p>ก ข ฃ ค ฅ ฆ ง จ ฉ ช ซ ฌ ญ ฎ ฏ ฐ ฑ ฒ ณ ด ต ถ ท ธ น บ ป ผ ฝ พ ฟ ภ ม ย ร ล ว ศ ษ ส ห ฬ อ ฮ
          ะ ั า ำ ิ ี ึ ื ุ ู โ ใ ไ ฤ ฤๅ ฦ ฦๅ  ้ ๊ ๋ ็</p>

        <h3 class="font-sans text-xl">Russian <span class="text-[#458600]">(129/129)</span></h3>
        <p>А Б В Г Д Е Ё Ж З И Й К Л М Н О П Р С Т У Ф Х Ц Ч Ш Щ Ъ Ы Ь Э Ю Я
          а б в г д е ё ж з и й к л м н о п р с т у ф х ц ч ш щ ъ ы ь э ю я</p>

        <h3 class="font-sans text-xl">Japanese <span class="text-timeout-red">(153/313)</span></h3>
        <p>あ い う え お か き く け こ さ し す せ そ た ち つ て と な に ぬ ね の は ひ ふ へ ほ ま み む め も や ゆ よ ら り る れ ろ わ を ん
          ア イ ウ エ オ カ キ ク ケ コ サ シ ス セ ソ タ チ ツ テ ト ナ ニ ヌ ネ ノ ハ ヒ フ ヘ ホ マ ミ ム メ モ ヤ ユ ヨ ラ リ ル レ ロ ワ ヲ ン
          人 一 日 本 語 学 生 月 木 金 水 火 土 曜 日 年 今 何 百 千 円 元 外 国 会 社 会 町 都 道 府 県 車 男 女 子 父 母 友 高 校 大 学 小 中 高 美 術 音 楽 科 学 歴 史 地 理 英 語 数 学 体 育 月 火 水 木 金 土</p>

        <h3 class="font-sans text-xl">Turkish <span class="text-[#458600]">(114/114)</span></h3>
        <p>A B C Ç D E F G Ğ H I İ J K L M N O Ö P R S Ş T U Ü V Y Z
          a b c ç d e f g ğ h ı i j k l m n o ö p r s ş t u ü v y z</p>
      </div>
    </div>
  </div>
</template>

<style>
html body {
  background: white;
}
</style>
