import { defineStore } from 'pinia';
import { useAppStateStore } from './appState';
import { useFetch, type UseFetchReturn } from '@vueuse/core'
import type { Pin, PinGeoJsonFeature } from '@/types';
import { sortPinsByProximity, geojson2coords, sortPinsByProximityToEachother } from '@/utils/haversine';
import { Endpoint, useApiUrl } from '@/composables/useApiUrl';
import { watchEffect, ref } from 'vue';
import { useMapStore } from './map';
import defaultPinImage from '@/assets/images/pin.png'
import { useTourStore } from "@/stores/tour";
import { useLanguagesStore } from "@/stores/languages";

export const usePinsStore = defineStore('pins', {
  state: () => ({
    pinsMap: new Map<number, Pin>(),
    pinsFeatures: [] as PinGeoJsonFeature[],
    tierOnePinsFeatures: [] as PinGeoJsonFeature[],
    activePin: undefined as Pin | undefined,
    pinsByDistance: [] as Pin[],
    tierOneCount: 0,
  }),
  actions: {
    newPinSelected(pinId: number) {
      console.log('CHANGING ACTIVE PIN FROM', this.activePin?.id, ' to ', pinId)

      // TODO - later we may need to check this as an Event Tour will set pins (Global tour does not)
      const tourStore = useTourStore()
      tourStore.stopTour()

      this.activePin = this.pinsMap.get(pinId)
    },

    clearPinSelected() {
      console.log('CLEARING ACTIVE PIN')
      this.activePin = undefined
      const mapsStore = useMapStore()
      mapsStore.clearPulsingMarker()
    },

    fetchPins() {
      // Fetch all the map pins.
      const pins = ref<Pin[]>([])
      const appStateStore = useAppStateStore()
      const mapStore = useMapStore()
      const languagesStore = useLanguagesStore()

      const url = useApiUrl(Endpoint.GeoPins)

      // prevent dispatching the fetch twice
      if (pins.value.length > 0) return

      const { data, error, isFinished }: UseFetchReturn<Pin[]>
        = useFetch<Pin[]>(url).get().json()

      watchEffect(async () => {
        if (error.value || (isFinished.value && !data.value)) {
          // TODO: handle non 200 errors
          return;
        }

        // if the request is not finished, do nothing
        if (!isFinished.value) return;

        // double check that there is data
        if (!data.value) {
          console.log("warn: request finished but no data found in data");
          return;
        }

        // prevent loading the pins twice
        if (pins.value.length > 0) return

        // Loop through the pins and remove the language prefixes from their aliases.
        data.value.forEach((pin: Pin) => {
          pin.alias = languagesStore.removeURLPrefix(pin.alias, languagesStore.getCurrentLanguageURLPrefix())
        })

        pins.value = data.value

        console.log("PINS LOADED", pins.value)

        pins.value.forEach(async (pin) => {
          if (isNaN(pin.coordinates[0]) || isNaN(pin.coordinates[1])) console.log('COORDINATES NOT A NUMBER', pin.id, JSON.stringify(pin.coordinates))
          this.pinsMap.set(pin.id, pin)

          if (!pin.marker_image) pin.marker_image = defaultPinImage

          if (pin.tier == 1 && pin.type == 'event') {
            this.tierOneCount += 1

            const rawTitle = document.createElement("textarea");
            rawTitle.innerHTML = pin.title;

            this.tierOnePinsFeatures.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: pin.coordinates
              },
              properties: {
                id: pin.id,
                alias: pin.alias,
                title: pin.title,
                rawTitle: rawTitle.value,
                image: pin.image,
                marker_image: pin.marker_image
              }
            })

            return
          }

          if (pin.type === 'article' || (pin.tier === 1 && pin.type !== 'event')) {
            // Don't add to map if it is either content, or a tier 1 that is
            // not an event.
            return
          }

          this.pinsFeatures.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: pin.coordinates
            },
            properties: {
              id: pin.id,
              alias: pin.alias,
              title: pin.title,
              image: pin.image,
              marker_image: pin.marker_image
            }
          })
        })

        this.pinsByDistance = sortPinsByProximityToEachother([...this.pinsMap.values()])

        // Exclude content and Tastemaker pins from the map.
        appStateStore.pinsHaveLoaded()

        await mapStore.loadImages([...this.pinsFeatures, ...this.tierOnePinsFeatures])
        if (this.pinsFeatures.length) mapStore.addMapPins(this.pinsFeatures)
        if (this.tierOnePinsFeatures.length) mapStore.addTierOneMapPins(this.tierOnePinsFeatures)
      })
    },

    getTier1EventPins(): Pin[] {
      return [...this.pinsMap.values()].filter((pin: Pin): pin is Pin => pin.tier == 1 && pin.type == 'event');
    },

    /**
     * Takes the given list of pins and sorts it by proximity.
     *
     * @param pins
     * @param geoJson
     */
    sortPinsByProximity(pins: Pin[], coordinates: [number, number]): Pin[] {
      return sortPinsByProximity(pins, geojson2coords(coordinates)) as Pin[]
    },

    /**
     * Takes the given list of pins and sorts it by sticky.
     * Also moves any previously viewed Tier2+ to the end.
     *
     * @param pins
     */
    sortPinsForUser(pins: Pin[]): Pin[] {
      const foodmarksViewed: number[] = localStorage.getItem('foodmarksViewed')?.split(',').map(Number) || []

      // Update foodmarksViewed with any items in the queue older than 5 minutes.
      const foodmarksViewedQueue: String[] = localStorage.getItem('foodmarksViewedQueue')?.split(',').filter((str) => str !== '') || []
      foodmarksViewedQueue.forEach((item, index) => {
        const itemData = item.split(':').map(Number)
        // Is the item older than 5 minutes?
        if (Date.now() - itemData[0] > 300000) {
          if (foodmarksViewed.indexOf(itemData[1]) === -1) {
            foodmarksViewed.push(itemData[1])
          }
          foodmarksViewedQueue.splice(index, 1)
        }
      })

      // Update the local storage based on items moving from the queue to the viewed list.
      localStorage.setItem('foodmarksViewed', foodmarksViewed.join(','))
      localStorage.setItem('foodmarksViewedQueue', foodmarksViewedQueue.join(','))

      // Move any non tier 1 pins that have already been viewed to the end of the list.
      pins = pins.sort((a: Pin, b: Pin) => {
        if (a.tier === 1 && b.tier !== 1) return -1
        if (a.tier !== 1 && b.tier === 1) return 1
        if (foodmarksViewed.includes(a.id) && !foodmarksViewed.includes(b.id)) return 1
        if (!foodmarksViewed.includes(a.id) && foodmarksViewed.includes(b.id)) return -1
        return 0
      })

      // Move any "Sticky" pins to the front of the list.
      pins = pins.sort((a: Pin, b: Pin) => {
        if (a.sticky && !b.sticky) return -1
        if (!a.sticky && b.sticky) return 1
        return 0
      })

      return pins
    }
  }
})
