<template>
  <div class="space-y-[10px] border-timeout-red flex flex-col justify-center" :style="{
    borderLeftWidth: recommendation ? '3px' : '0px',
    paddingLeft: recommendation ? '1.5rem' : '0px',
  }">
    <span class="font-bold text-sm uppercase" v-if="recommendation" v-html="appStringsStore.get('recommendedBy')" />
    <div class="flex space-x-6">
      <img loading="lazy" class="my-0 rounded-full object-cover" v-if="image" :src="image" alt="" :style="{
        width: authorSize[size].width,
        height: authorSize[size].height,
      }" />
      <div class="flex flex-col">
        <a v-if="URL && !username" :href="URL" target="_blank" class="no-underline hover:underline font-bold text-xl leading-snug my-0"
           v-html="name"
           :style="{ fontSize: authorSize[size].fontSize } "/>
        <span v-else class="font-bold text-xl leading-snug my-0 pr-24" v-html="name"
          :style="{ fontSize: authorSize[size].fontSize }"></span>
        <a v-if="URL && username" :href="URL" target="_blank" class="no-underline hover:underline my-0 text-lg pr-24" v-html="username" />
        <span v-else-if="username" class="my-0 text-lg pr-24" v-text="username"
          :style="{ fontSize: authorSize[size].fontSizeSmall }"></span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AuthorSize } from '@/types'
import {useAppStringsStore} from "@/stores/appStrings";

const appStringsStore = useAppStringsStore()

defineProps<{
  image?: string
  name: string
  username?: string
  URL?: string
  size: AuthorSize
  recommendation?: boolean
}>()

const authorSize = {
  [AuthorSize.Small]: {
    width: '50px',
    height: '50px',
    fontSize: '17px',
    fontSizeSmall: '14px'
  },
  [AuthorSize.Large]: {
    width: '100px',
    height: '100px',
    fontSize: '24px',
    fontSizeSmall: '17px'
  }
}

</script>
