<template>
  <aside v-if="!mapStore.mapCreationError" id="mobile-slideout" class="max-w-full	h-full sidebar-contents rounded-t-md" :class="{'pane-minimised': mobileSlideoutStore.isMinimised}">
    <div class="flex pt-6 justify-center w-full z-10 relative lg:hidden">
      <div class="w-[36px] h-[5px] rounded-full bg-mid-grey" style="box-shadow: 0 0 3px #00000042;" />
    </div>
    <RouterView v-if="isMobile" />
    <SlideoutFooter v-if="isMobile" />
  </aside>
  <aside v-if="!isMobile || mapStore.mapCreationError" class="sidebar-contents float-right md:relative"
    :class="{
      'lg:w-[var(--sidebar-lg-width)] xl:w-[var(--sidebar-xl-width)]': !mapStore.mapCreationError,
      'md:w-4/5 w-full max-w-2xl': mapStore.mapCreationError
    }"
    style="min-height: 100svh;">
    <RouterView />
    <SlideoutFooter />
  </aside>
</template>

<style>
.cupertino-pane-wrapper .pane {
  max-width: 30rem;
  padding-top: 0;
}

.cupertino-pane-wrapper .draggable {
  display: none;
}

.sidebar-contents {
  @apply bg-white text-base-grey shadow-[0_4px_4px_0_rgba(0,0,0,0.5)] flex flex-col;
}

.sidebar-contents .close-button {
  @apply opacity-100 transition-all duration-100;
}
.sidebar-contents.pane-minimised .close-button {
  @apply opacity-0 pointer-events-none;
}
</style>

<script setup lang="ts">
import SlideoutFooter from './SlideoutFooter.vue';
import useIsMobile from '@/composables/useIsMobile'
import { useMapStore } from '@/stores/map';
import { useMobileSlideoutStore } from '@/stores/mobileSlideout';

const isMobile = useIsMobile()
const mobileSlideoutStore = useMobileSlideoutStore();

mobileSlideoutStore.initialize("#mobile-slideout");
const mapStore = useMapStore()

</script>

