import { CupertinoPane, type CupertinoSettings } from 'cupertino-pane';
import { defineStore } from 'pinia';
import { onMounted, onUnmounted, ref, watch, watchEffect } from 'vue';
import useIsMobile from '@/composables/useIsMobile';
import { useMapStore } from './map';

const isMobile = useIsMobile();

export const mobileBreakpoints = ['bottom', 'top', 'middle'] as const
export type MobileBreakpoint = typeof mobileBreakpoints[number]

export const useMobileSlideoutStore = defineStore('mobileSlideout', {
  state: () => ({
    pane: undefined as CupertinoPane | undefined,
    height: window.innerHeight,
    scroll: 0,
    isMinimised: true,
    ready: false,
  }),
  actions: {
    initialize(selector: string) {
      this.watchScroll()

      const breakPoints = () => ({
        top: {
          name: 'top',
          enabled: true,
          bounce: false,
          // Needed to offset the top break from the Tour buttons
          height: window.innerHeight - 125
        },
        middle: {
          enabled: true,
          height: window.innerHeight * 0.66,
          bounce: false,
        },
        bottom: {
          enabled: true,
          height: 50,
          bounce: false,
        },
      })

      const settings: (
        initialBreak?: MobileBreakpoint,
        animate?: boolean) =>
        CupertinoSettings = (initialBreak) => ({
          // sets it to the bottom of the screen initially
          initialBreak: initialBreak || 'middle',
          // removes default close button
          buttonDestroy: false,
          // disable clicking on close button opening pane
          clickBottomOpen: false,
          breaks: breakPoints(),
          showDraggable: false,
          events: {
            onTransitionEnd: () => {
              setTimeout(() => {
                this.isMinimised = this.pane?.currentBreak() == 'bottom'
              }, 1)
            }
          }
        })

      const mapStore = useMapStore()

      const heightHandler = () => {
        if (this.height !== window.outerHeight) {
          this.height = window.outerHeight
        }
      }

      onMounted(() => {
        window.addEventListener('resize', heightHandler)

        // watch for the map to load, and then create the pane
        watch(() => mapStore.loaded, () => {
          if (!mapStore.loaded || this.pane) return;
          this.pane = new CupertinoPane(selector, settings())
        }, { immediate: true })


        const prevBreakAsBreak = () => {
          const pBreak = this.pane?.currentBreak()
          if (!pBreak) return undefined;
          // explicitly check it's one of the 3 breaks
          if (mobileBreakpoints.includes(pBreak as MobileBreakpoint)) return pBreak as MobileBreakpoint;
          return undefined
        }

        // Watch height and recreate pane when it changes
        const timeout = ref<number | undefined>(undefined)
        const prevBreak = ref(prevBreakAsBreak())

        watch(() => this.height, () => {
          if (!isMobile.value || !this.pane) return;
          if (!prevBreak.value) prevBreak.value = prevBreakAsBreak()

          // we need to destroy the pane in-order to have vertical
          // responsivess, the current version of cupertino-pane's
          // does not work for this scenario
          if (this.pane.isPanePresented()) {
            this.pane.destroy()
          }

          // debounce
          if (timeout.value) window.clearTimeout(timeout.value)

          // handle resize after debouncing
          timeout.value = window.setTimeout(async () => {
            if (!this.pane) return;
            // create a new pane as the old one is destroyed
            this.pane = new CupertinoPane(selector, settings(prevBreak.value))

            // using id rather than pane.el as pane.el seems to be weakly referenced
            const mobileSlideout = document.getElementById('mobile-slideout')
            if (!mobileSlideout) return;

            // wait for the pane to be rendered
            await new Promise((resolve) => {
              // this interval should never run more than once,
              // just adding in-case there is a slow pc or something that
              // would break it
              const i = window.setInterval(() => {
                if (this.pane?.rendered) {
                  window.clearInterval(i)
                  resolve(undefined)
                }
              }, 10)
            })

            // once the pane is rendered, scroll to the previous position
            mobileSlideout.scrollTo(0, this.scroll)
          }, 100)
        })
      })

      onUnmounted(() => {
        window.removeEventListener('resize', heightHandler)
      })

      watchEffect(async () => {
        if (!this.pane) return;

        if (isMobile.value) {
          this.pane.present({ animate: true })
          this.ready = true
          return;
        }
        this.pane.destroy()
      });
    },
    setScroll(scroll: number) {
      this.scroll = scroll
    },
    watchScroll() {
      onMounted(() => {
        // using id rather than pane.el as pane.el seems to be weakly referenced
        const el = document.getElementById('mobile-slideout')
        if (!el) return;
        el.addEventListener('scroll', () => {
          this.scroll = el.scrollTop
        });
      });
    },
    scrollTop() {
      if (!this.pane) return;
      this.pane.el.scrollTop = 0
    },
    partialOpen() {
      if (!this.pane) return;
      console.log("openMiddle")
      this.pane.moveToBreak('middle')
    },
    open() {
      if (!this.pane) return;
      console.log("openFull")
      this.pane.moveToBreak('top')
    },
    close() {
      if (!this.pane) return;
      console.log("close")
      this.pane.moveToBreak('bottom');
    },
    scrollToTop() {
      if (!this.pane) return;
      this.pane.el.scrollTop = 0
    }
  }
})
