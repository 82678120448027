<template>
  <div v-if="!mapStore.mapCreationError" class="flex h-screen w-full relative">
    <div ref="mapContainer" class="absolute top-0 left-0 w-full h-full">
    </div>
    <div class="absolute flex gap-2.5 top-11 left-6 lg:top-6">
      <LocationButton @click="mapStore.goToUserLocation(router)" />
      <TourStatusButton v-if="tourStore.tourPins.length" @click="() => tourStore.toggleTourPlaying(true, router)" />
    </div>
    <SiteLoading />
  </div>
</template>

<script setup lang="ts">
import mapboxgl from 'mapbox-gl';
import { onMounted, onUnmounted, ref } from 'vue';
import { useMapStore } from '@/stores/map'
import { useTourStore } from "@/stores/tour"
import { useResizeObserver } from '@vueuse/core'
import { useRouter } from 'vue-router'

import LocationButton from '@/components/LocationButton.vue'
import TourStatusButton from '@/components/TourStatusButton.vue'
import SiteLoading from "@/components/SiteLoading.vue";

if (!import.meta.env['VITE_MAPBOX_GL_ACCESS_TOKEN']) {
  throw new Error("MAPBOX_ACCESS_TOKEN is not set")
}

mapboxgl.accessToken = import.meta.env['VITE_MAPBOX_GL_ACCESS_TOKEN'] || '';

const mapContainer = ref<HTMLElement>();

const mapStore = useMapStore();
const tourStore = useTourStore()
const hasInitializedMap = ref<boolean>(false)
const router = useRouter()

onMounted(async () => {
  if (!mapContainer.value || hasInitializedMap.value) return;
  hasInitializedMap.value = true
  mapStore.initializeMap(mapContainer.value);
});

onUnmounted(() => {
  mapStore.removeMap();
});

useResizeObserver(mapContainer, (entries) => {
  window.requestAnimationFrame(() => {
    if (!Array.isArray(entries) || !entries.length) return;
    mapStore.resize();
  })
});
</script>

<style>
.mapboxgl-control-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}


.mapboxgl-ctrl-attrib-inner {
  justify-content: space-between;
  background: #00000063;
  color: white;
  pointer-events: all;
}

.mapbox-improve-map {
  font-weight: bold;
}

.mapboxgl-ctrl-attrib-inner>a {
  margin: 0 0.5rem;
}

.mapboxgl-ctrl-attrib-inner>a:hover {
  text-decoration: underline;
}

.mapboxgl-ctrl-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.mapboxgl-ctrl-logo {
  width: 88px;
  height: 23px;
  background-image: url("/images/mapbox.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin: .5rem;
  pointer-events: all;
}

.mapboxgl-ctrl-attrib-button {
  display: none;
}

@media (max-width: 1023px) {
  .mapboxgl-ctrl-bottom-right {
    bottom: 0;
    top: auto;
  }
}

@media (max-width: 500px) {
  .mapboxgl-ctrl-bottom-right {
    bottom: 0;
    top: auto;
  }

  .mapboxgl-compact>.mapboxgl-ctrl-attrib-inner {
    opacity: 0;
    visibility: hidden;
  }

  .mapboxgl-compact-show>.mapboxgl-ctrl-attrib-inner {
    opacity: 1;
    visibility: visible;
  }

  .mapboxgl-ctrl-attrib {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .mapboxgl-ctrl-attrib-inner {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }

  .mapboxgl-ctrl-attrib-button {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: .5rem;
    pointer-events: all;
  }
}

.mapboxgl-ctrl-icon {
  background-image: url("/images/info.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: block
}

.marker {
  background-image: url(@/assets/images/pin.png);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 80px;
  transform-style: preserve-3d;
  transform: translateZ(-1px);
}

.marker-pulser {
  background: #F40000;
  border-radius: 100%;
  content: '';
  position: absolute;
  height: 110px;
  width: 110px;
  left: -28px;
  top: -28px;
  opacity: 0;
}

.marker-pulser.animated {
  animation: markerPulse 2s ease-in-out infinite;
}

@keyframes markerPulse {
  0% {
    transform: scale(0.1) translateZ(-1px);
    opacity: 0.0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2) translateZ(-1px);
    opacity: 0;
  }
}

.userMarker {
  background: #F40000;
  border-radius: 100%;
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  left: -8px;
  top: -8px;
  border: 2px solid white;
  opacity: 0.5;
}
</style>
