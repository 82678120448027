import { defineStore } from 'pinia'
import { useMapStore } from './map'
import { watchEffect } from 'vue'
import { type ActiveHeadEntry, useHead, type UseHeadInput } from "@unhead/vue";
import { type LanguageURLs } from "@/types";
import { type Router } from "vue-router";
import { useLanguagesStore } from "@/stores/languages";

export const useAppStateStore = defineStore('appState', {
  state: () => ({
    appLoading: true,
    stringsReady: false,
    pinsReady: false,
    pagesReady: false,
    pageTitle: undefined as ActiveHeadEntry<UseHeadInput<any>> | undefined | void,
    hrefLangTags: undefined as ActiveHeadEntry<UseHeadInput<any>> | undefined | void,
    metaTags: undefined as ActiveHeadEntry<UseHeadInput<any>> | undefined | void,
    lastPage: window.location.href,
    pageCount: 0
  }),
  actions: {
    stringsHaveLoaded() {
      this.stringsReady = true
    },

    pinsHaveLoaded() {
      this.pinsReady = true
    },

    pagesHaveLoaded() {
      this.pagesReady = true
    },

    watchLoaded() {
      watchEffect(() => {
        const mapStore = useMapStore()
        if (this.stringsReady && this.pinsReady && this.pagesReady && mapStore.loaded) {
          this.appLoading = false
        }

        // map has errored, still display the page.
        if (this.stringsReady && mapStore.mapCreationError) {
          this.appLoading = false
        }
      })
    },

    setHrefLangTags(urls: LanguageURLs | null | undefined, pageType: string, router: Router) {
      const languagesStore = useLanguagesStore()
      pageType = pageType.toString().split(':')[0]

      if (!this.hrefLangTags) {
        this.hrefLangTags = useHead({})
      }

      // Catch if setting failed.
      if (!this.hrefLangTags) {
        console.log('useHead failed to load.')
        return
      }

      this.hrefLangTags.dispose()

      if (urls) {
        const hrefLangTags = []

        // Loop through the URLS and add the hreflang tags.
        for (const [code, url] of Object.entries(urls)) {
          const urlLanguage = languagesStore.getLanguage(code)
          const noLangcodeAlias = languagesStore.removeURLPrefix(url, languagesStore.getLanguageURLPrefix(urlLanguage))
          const route = router.resolve({ name: `${pageType}:${code}`, params: { alias: url } });
          const URLPrefix = languagesStore.getLanguageURLPrefix(urlLanguage)
          const isHome = route.name === `home:${code}`
          const localisedURL = `/${URLPrefix?URLPrefix+'/':''}${route.meta.typePrefix?route.meta.typePrefix:''}${isHome?'':'/'+noLangcodeAlias}`

          hrefLangTags.push({
            rel: 'alternate',
            hreflang: code,
            href: localisedURL
          })
        }
        this.hrefLangTags = useHead({ link: hrefLangTags })
      }
    },

    setMetaTags(newMetaTags: Record<string, string>) {
      console.log('SET METATAGS', newMetaTags)
      if (!this.metaTags) {
        this.metaTags = useHead({})
      }
      if (!this.pageTitle) {
        this.pageTitle = useHead({})
      }

      // Catch if setting failed.
      if (!this.metaTags) {
        console.log('useHead failed to load.')
        return
      }

      this.metaTags.dispose()

      if (newMetaTags) {
        const metaTagBuild = []

        // Loop through the tags and add them to the build array.
        for (const [tag, value] of Object.entries(newMetaTags)) {
          if (tag === 'title' && this.pageTitle) {
            this.pageTitle.dispose()
            this.pageTitle = useHead({ title: value } )
          }
          else if (tag === 'refresh') {
            metaTagBuild.push({
              'http-equiv': 'refresh',
              content: value,
            })
          }
          else {
            metaTagBuild.push({
              name: tag,
              content: value
            })
          }
        }

        this.metaTags = useHead({ meta: metaTagBuild })
      }
    },

    updatePageCount() {
      if (window.location.href !== this.lastPage) {
        this.pageCount++
        this.lastPage = window.location.href
      }
    }
  }
})
