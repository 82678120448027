import { defineStore } from 'pinia'
import { watchEffect } from "vue"
import { useFetch, type UseFetchReturn } from "@vueuse/core"
import type { Language } from "@/types";
import { useHead } from '@unhead/vue'
import { Endpoint, useApiUrl } from '@/composables/useApiUrl';

const defaultLanguage = {
  "id": "en",
  "name": "English",
  "urlPrefix": "",
  "nativeName": "English",
  "direction": "ltr",
  "isDefault": true,
  "urlTypePrefixes": {
    "storiesURLPrefix": "go",
    "contentURLPrefix": "content",
    "favouritesURLPrefix": "favorites",
    "searchURLPrefix": "search",
    "locationURLPrefix": "location"
  }
} as Language

export const useLanguagesStore = defineStore('languages', {
  state: () => ({
    languages: [defaultLanguage] as Language[],
    userLanguage: undefined as undefined | Language,
    activeLanguage: undefined as undefined | Language,
    defaultLanguage: defaultLanguage
  }),
  actions: {
    fetch() {
      const url = useApiUrl(Endpoint.Languages)
      const { data, isFinished }: UseFetchReturn<Language[]> = useFetch(url).get().json()

      watchEffect(() => {
        if (isFinished.value) {
          this.languages = data.value ?? []
          this.defaultLanguage = this.languages.find((language: Language) => language.isDefault) ?? this.languages[0]
          console.log(this.defaultLanguage)
        }
      })
    },

    getLanguage(prefix: string): Language {
      return this.languages.find((language: Language) => language.urlPrefix === prefix) ?? this.getDefaultLanguage()
    },

    getURLModifier() {
      return this.getLanguageURLPrefix(this.getActiveLanguage())
    },

    getCurrentPathWithLanguage(language: Language): string {
      const url = window.location.pathname
      const urlParts = url.split('/').filter(Boolean);
      const urlLang = this.getUrlLanguage(url)
      const urlPartsWithoutLang = urlParts.filter((part) => part !== urlLang?.id)
      const urlModifier = language?.isDefault ? '' : `${language?.id}/` ?? ''

      return `/${urlModifier}${urlPartsWithoutLang.join('/')}`
    },

    getActiveLanguage(): Language {
      if (!this.activeLanguage) {
        this.activeLanguage = this.getUrlLanguage() ?? this.defaultLanguage
      }

      return this.activeLanguage
    },

    getDefaultLanguage(): Language {
      return this.defaultLanguage
    },

    getUrlLanguage(url: string = window.location.pathname) {
      // Vue router is not yet available here.
      const urlParts = url.split('/').filter(Boolean)
      const language = this.getLanguage(urlParts[0])

      return language ?? this.getLanguage(this.getDefaultLanguage()?.id ?? 'en')
    },

    getUserLanguage(): Language {
      // Return the manually selected language if it exists.
      if (localStorage.getItem('languageManuallySet')) {
        return this.getLanguage(localStorage.getItem('languageManuallySet') ?? 'en')
      }
      // Otherwise get it from their browser.
      else {
        const langcode = this.getBrowserLanguageID() ?? this.getDefaultLanguage()?.id
        return this.getLanguage(langcode)
      }
    },

    getBrowserLanguageID() {
      return navigator.language.split('-')[0] ?? null
    },

    setMetatags() {
      const language = this.getActiveLanguage()
      document.documentElement.lang = language.id
      document.documentElement.dir = language.direction

      useHead({
        meta: [
          {
            "http-equiv": 'content-language',
            content: language.id
          },
          {
            name: 'og:locale',
            content: language.id
          }
        ]
      })
    },

    getLanguageURLPrefix(language: Language) {
      return language.isDefault ? '' : encodeURI(language.urlPrefix)
    },

    getCurrentLanguageURLPrefix() {
      return this.getLanguageURLPrefix(this.getActiveLanguage())
    },

    removeURLPrefix(url: string, prefix: string) {
      const re = new RegExp('^/?' + prefix?prefix+'/':'', "i")
      url = url.replace(re , '').replace(/^\/+/, '')

      return url
    }
  }
})
