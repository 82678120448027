<script setup lang="ts">
import { useTourStore } from "@/stores/tour"
import { computed, ref, watchEffect, watch } from 'vue'
import { useAppStringsStore } from "@/stores/appStrings";
import useIsMobile from "@/composables/useIsMobile";
import { useLanguagesStore } from "@/stores/languages";

const tourStore = useTourStore()
const appStringsStore = useAppStringsStore()

const isMobile = useIsMobile()

const props = defineProps<{
  sidebar?: boolean
}>()

enum TourState {
  PLAYING = 'playing',
  PAUSED = 'paused',
  FLIGHT_COUNTDOWN = 'flightCountdown'
}

const tourState = ref<TourState>(TourState.PAUSED)
const tourButtonSizeTransitioning = ref(false)

const widthCache = new Map<string, number>()
const widthCalculatingCanvas = document.createElement('canvas')

const getTextWidth = (text: string, font: string): number => {
  // cache to prevent using a canvas constantly
  const cacheKey = `${text}-${font}`
  const cachedWidth = widthCache.get(cacheKey)

  if (cachedWidth) return cachedWidth

  // if no cache, calculate width using the canvas measureText function
  const canvas = widthCalculatingCanvas
  const ctx = canvas.getContext("2d");
  if (!ctx) return 0

  ctx.font = font;

  const width = ctx.measureText(text).width

  widthCache.set(cacheKey, width)
  return width
}

const tourButtonText = computed(() => {
  if (isMobile.value && !props.sidebar) {
    if (tourState.value === TourState.FLIGHT_COUNTDOWN)
      return `${(tourStore.tourTimeRemaining ?? 0) / 1000}s`

    return ""
  }
  if (tourState.value === TourState.FLIGHT_COUNTDOWN)
    return `${appStringsStore.get('tourFlying')} ${(tourStore.tourTimeRemaining ?? 0) / 1000}s`
  if (tourState.value === TourState.PLAYING) return appStringsStore.get('tourPause')

  if (tourState.value === TourState.PAUSED) return appStringsStore.get('tourContinue')

  return ""
})

const tourButtonWidth = computed(() => {
  if (tourButtonText.value.length === 0) return 40

  if (isMobile.value && props.sidebar) {
    return '100%'
  }

  const languagesStore = useLanguagesStore()

  // with more precision it changes size for every different number
  // rounds to the nearest 10
  const lang = languagesStore.getActiveLanguage().id
  const fontSuffix = lang == 'th' ? ' Thai' : lang == 'zh-hant' ? ' TC' : lang == 'jp' ? ' JP' : lang == 'ko' ? ' KR' : ''
  return Math.ceil((getTextWidth(tourButtonText.value, `bold 19px "Noto Sans${fontSuffix}"`) + 61) / 10) * 10
})

// delayed properties are used to make sure the animation order is
// 1. text hiding
// 2. button width shrinking / growing & text changing
// 3. text showing

const tourButtonWidthDelayed = ref(tourButtonWidth.value)
const tourButtonTextDelayed = ref(tourButtonText.value)
const prevTourButtonState = ref(tourState.value)

watchEffect(() => {
  const prevState = prevTourButtonState.value

  if (tourState.value !== TourState.FLIGHT_COUNTDOWN || !tourStore.tourTimeRemaining) {
    tourButtonSizeTransitioning.value = true
  }

  prevTourButtonState.value = prevState

  if (tourStore.tourTimeRemaining) {
    tourState.value = TourState.FLIGHT_COUNTDOWN
    return
  }
  if (tourStore.tourPlaying) {
    tourState.value = TourState.PLAYING
    return
  }
  tourState.value = TourState.PAUSED
})


watchEffect(() => {
  // only instantly change value if it's counting down and not at 10s,
  // we still want the transition from "pause" to the countdown
  if (!tourStore.tourTimeRemaining || tourStore.tourTimeRemaining === 10000) return
  tourButtonSizeTransitioning.value = false
  tourButtonWidthDelayed.value = tourButtonWidth.value
  tourButtonTextDelayed.value = tourButtonText.value
})

watch(() => tourButtonText.value, () => {
  if (!!tourStore.tourTimeRemaining && tourStore.tourTimeRemaining !== 10000) return
  // wait for text to hide
  setTimeout(() => {
    tourButtonWidthDelayed.value = tourButtonWidth.value
    // change text whilst hidden
    tourButtonTextDelayed.value = tourButtonText.value
    // wait for width to change before showing text
    setTimeout(() => {
      tourButtonSizeTransitioning.value = false
    }, 200)
  }, 200)
}, { immediate: true })

</script>

<template>
  <button :style="{
    width: `${tourButtonWidthDelayed}px`
  }" class="font-bold font-sansTitle
    flex justify-start items-center space-x-3
    text-[19px] group h-10
    rounded-md transition-all duration-0 motion-safe:duration-100
    text-white bg-coca-cola-red hover:bg-coca-cola-red-alt" :class="{
      'px-4': tourButtonTextDelayed.length !== 0,
      'pl-[.8rem]': tourButtonTextDelayed.length === 0
    }"
    :aria-label="tourState === TourState.PAUSED ? appStringsStore.get('tourContinue') : appStringsStore.get('tourPause')">
    <Transition name="fade" mode="out-in">
      <svg v-if="tourStore.tourPlaying" width="12" height="18" viewBox="0 0 12 18" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.85714 17.5H1.28571C0.575893 17.5 0 16.6842 0 15.6786V2.32143C0 1.31585 0.575893 0.5 1.28571 0.5H3.85714C4.56696 0.5 5.14286 1.31585 5.14286 2.32143V15.6786C5.14286 16.6842 4.56696 17.5 3.85714 17.5ZM12 15.6786V2.32143C12 1.31585 11.4241 0.5 10.7143 0.5H8.14286C7.43304 0.5 6.85714 1.31585 6.85714 2.32143V15.6786C6.85714 16.6842 7.43304 17.5 8.14286 17.5H10.7143C11.4241 17.5 12 16.6842 12 15.6786Z"
          fill="white" />
      </svg>
      <svg v-else width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="fill-white"
          d="M1.62539 0.10632L12.9257 6.96516C13.6914 7.43445 13.6914 8.57759 12.9257 9.04688L1.62539 15.8937C1.12651 16.1945 0.5 15.8215 0.5 15.2198V0.780171C0.5 0.178519 1.12651 -0.194506 1.62539 0.10632Z" />
      </svg>
    </Transition>
    <span class="group-hover:text-white line-clamp-1 transition-all duration-100" :class="{
      'opacity-0': tourButtonSizeTransitioning,
    }">{{ tourButtonTextDelayed }}</span>
  </button>
</template>

<style scoped>
@media (prefers-reduced-motion: no-preference) {

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s ease-out;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>

