import mapboxgl, { type AnyLayer, type MapboxOptions, type PointLike } from 'mapbox-gl'
import { defineStore } from 'pinia'
import { usePinsStore } from './pins';
import { useLanguagesStore } from './languages';
import { haversineDistance, isDistanceLessThanRange, sortPinsByProximity } from '@/utils/haversine';
import type { Coordinates, Pin, PinGeoJsonFeature, Image } from '@/types'
import { useTourStore } from "@/stores/tour"
import * as Fathom from 'fathom-client';
import { ref, watch, watchEffect } from 'vue';
import { type RouteLocationRaw, type Router } from 'vue-router';
import { useMobileSlideoutStore, type MobileBreakpoint } from './mobileSlideout';
import { useAppStateStore } from './appState';
import { useGeolocation, useThrottleFn } from '@vueuse/core'
import jsonp from 'jsonp'

type MapStore = ReturnType<typeof useMapStore>;
type TourStore = ReturnType<typeof useTourStore>;

const interruptStrings = [
  'mousedown',
  'mouseup',
  'click',
  'touchstart',
  'touchend',
  'touchmove',
  'wheel',
  'dblclick',
  'contextmenu'
];

type FlyToOptions = {
  zoom?: number,
  hideMobile?: boolean,
  onMoveEndCallback?: Function,
  landingMobileBreakpoint?: MobileBreakpoint,
  offset?: PointLike
}

export const useMapStore = defineStore('map', {
  state: () => ({
    mapContainer: undefined as HTMLElement | undefined,
    loaded: false,
    pinsAdded: false,
    tierOnePinsAdded: false,
    styleLoaded: false,
    map: undefined as mapboxgl.Map | undefined,
    hasOnLoadActions: false,
    closeTier1Pin: undefined as Pin | undefined,
    mapIsMoving: false,
    mapSpinning: false,
    _mapSpinFunc: undefined as undefined | (() => void),
    _mapSpinInterruptFunc: undefined as undefined | (() => void),
    mapMoved: false,
    userLocation: undefined as Coordinates | undefined,
    userLocationRetrieving: false,
    userLocationRequested: false,
    mapCreationError: false,
    spinThrottle: undefined as ReturnType<typeof useThrottleFn> | undefined,
  }),
  actions: {
    initializeMap(mapContainer: HTMLElement) {
      const languagesStore = useLanguagesStore()
      const pinsStore = usePinsStore();
      const tourStore = useTourStore();

      mapboxgl.accessToken = import.meta.env['VITE_MAPBOX_GL_ACCESS_TOKEN'] || '';

      this.mapContainer = mapContainer;

      const initMap = (): [mapboxgl.Map | undefined, unknown | undefined] => {
        try {
          this.map = new mapboxgl.Map({
            container: mapContainer,
            style: 'mapbox://styles/timeoutlondon/clrj7vlyb00oz01pif6651i9d',
            center: [0, 0],
            zoom: 2,
            // Mapbox langcodes are case sensitive ¯\_(ツ)_/¯
            language: languagesStore.getActiveLanguage().id.replace('-han', '-Han'),
          } as MapboxOptions & { language: string });
          return [this.map, undefined]
        } catch (error: unknown) {
          return [undefined, error]
        }
      }

      const [map, err] = initMap()

      // still fetch pins if map fails to load, we want to show the content
      pinsStore.fetchPins()

      if (err || !map) {
        this.mapCreationError = true
        return
      }

      map.setMaxZoom(18)

      map.dragRotate.disable()
      map.touchZoomRotate.disableRotation()

      tourStore.buildTourGlobalPlaylist()


      watch(() => this.loaded, () => {
        if (!this.loaded || !this.map || this.hasOnLoadActions) return;

        addMapCursorEvents(this)
        addMapClusterClickEvent(this)
        addMapPinClickEvent(this.map, pinsStore, this)
        appMapDraggedEvent(this, tourStore)

        // Add missing role attribute to accreditation links.
        const accreditation = document.querySelector(".mapboxgl-ctrl-attrib-inner")
        if (accreditation) {
          accreditation.setAttribute('role', 'group')
          for (let i = 0; i < accreditation.children.length; i++) {
            // The improve map link is a special case, as it keeps getting replaced by Mapbox.
            if (accreditation.children[i].classList.contains('mapbox-improve-map')) {
              const observer = new MutationObserver(() => {
                // Set the aria-label to the text content of the link if it's different.
                if (accreditation.children[i].getAttribute('aria-label') !== accreditation.children[i].textContent) {
                  accreditation.children[i].setAttribute('aria-label', accreditation.children[i].textContent?.toString() ?? '')
                }
              });
              observer.observe(accreditation.children[i], {
                attributes: true,
                childList: false,
                subtree: false
              });
            } else {
              accreditation.children[i].setAttribute('aria-label', accreditation.children[i].textContent?.toString() ?? '')
            }
          }
        }
      }, { immediate: true })

      // initialize watcher to trigger above when loaded
      this.addOn('load', () => {
        const waitingForLoad = () => {
          if (!this.map?.getLayer('pinsClose')
            || !this.map?.getLayer('pinsFar')
            || !this.map?.getLayer('tierOnePinsClose')
            || !this.map?.getLayer('tierOnePinsFar')
            || !this.map?.getLayer('clusters')
            || !this.map?.getLayer('cluster-count')
            || !this.map?.getLayer('pinsVeryFar')
            || !this.map?.getLayer('pinsVeryFar-count')
          ) {
            setTimeout(waitingForLoad, 200)
          } else {
            this.loaded = true
          }
        };
        waitingForLoad()
      });

      watch(() => this.styleLoaded, () => {
        if (!this.map || !this.styleLoaded) return
      }, { immediate: true })

      // initialize watcher to trigger above when loaded
      this.addOn('styledata', () => {
        const waitingForStyle = () => {
          if (!this.map?.isStyleLoaded()) {
            setTimeout(waitingForStyle, 200)
          } else {
            this.styleLoaded = true
          }
        };
        waitingForStyle()
      });
    },
    loadImage(url: string) {
      return new Promise<HTMLImageElement | ImageBitmap | undefined>((resolve, reject) => {
        if (!this.map) {
          reject("no map")
          return
        }
        this.map.loadImage(url, (error, image) => {
          if (error || !image) reject(error);
          resolve(image);
        })
      })
    },
    loadImages(pinFeatures: PinGeoJsonFeature[]) {
      const uniqueImages = pinFeatures
        .map((pin) => pin.properties.marker_image)
        .filter((value, index, self) => value && self.indexOf(value) === index
          && !this.map?.hasImage(value))

      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async (resolve, reject) => {
        try {
          const imageResults = await Promise.allSettled(
            uniqueImages.map(async (url) => {
              if (!url) return
              return {
                url,
                image: await this.loadImage(url)
              } as Image
            }
            ).filter(Boolean)
          )

          const fulfilledImages = imageResults
            .filter((image) => image.status === 'fulfilled') as PromiseFulfilledResult<Image>[]

          const images = fulfilledImages.map((image) => image.value)

          images.forEach((image) => {
            if (!this.map || !image.image) return
            this.map.addImage(image.url, image.image)
          })
          resolve()
        } catch (err) {
          reject(`Error loading tier 1 pin images ${err}`)
        }
      })
    },
    reset(hideMobile: boolean = true) {
      this.flyTo([this.map?.getCenter().lng ?? 0, 0], { zoom: 2, hideMobile: hideMobile })
      this.startSpinning()
    },
    stopSpinning() {
      console.log("stop spinning")
      this.mapSpinning = false
      // Need to make sure we don't duplicate the event listener so remove it
      // when stopping spinning
      if (this._mapSpinFunc) this.addOff('moveend', this._mapSpinFunc)

      interruptStrings.forEach((interrupt) => {
        if (!this._mapSpinInterruptFunc) {
          this._mapSpinInterruptFunc = () => {
            this.stopSpinning()
          }
        }
        this.addOff(interrupt, this._mapSpinInterruptFunc)
      })
    },
    async startSpinning() {
      console.log("LOOKING TO START SPINNING")
      if (this.mapSpinning) return;
      console.log("STARTING SPINNING")
      this.mapSpinning = true

      interruptStrings.forEach((interrupt) => {
        if (!this._mapSpinInterruptFunc) {
          this._mapSpinInterruptFunc = () => {
            this.stopSpinning()
          }
        }
        this.addOn(interrupt, this._mapSpinInterruptFunc)
      })

      this._mapSpinFunc = () => {
        if (!this.mapSpinning) return

        if (!this.spinThrottle) {
          this.spinThrottle = useThrottleFn(() => {
            console.log("MOVE END")
            spinMap()
          }, 900, true)
        }
        this.spinThrottle()
      }

      this.addOn('moveend', this._mapSpinFunc)

      const spinMap = () => {
        if (!this.map || !this.mapSpinning) return
        // Backup check, see if at correct zoom level before spinning
        // Epsilon is used to account for floating point errors
        if (this.map.getZoom() < 2 - Number.EPSILON || this.map.getZoom() > 2 + Number.EPSILON) {
          return
        }

        const spinSpeed = 3
        const currentCenter = this.map.getCenter()

        if (!currentCenter) return

        const newLng = () => {
          if (currentCenter.lng > 180) return -180
          if (currentCenter.lng < -180) return 180
          return currentCenter.lng - spinSpeed
        }

        currentCenter.lng = newLng()
        this.map.easeTo({ center: currentCenter, duration: 1000, easing: (t) => t })
      }

      this.mapSpinning = true
      spinMap()

      if (this.mapSpinning) return
      this.stopSpinning()
    },
    async startWelcomeTour() {
      const appStateStore = useAppStateStore()
      // prevent starting the tour if this is not their first page, e.g.
      // gone to a pin and then back to home
      if (appStateStore.pageCount > 0) return;

      const tourStore = useTourStore()
      tourStore.playTour()
    },
    addTierOneMapPins(tierOnePinFeatures: PinGeoJsonFeature[]) {
      watch(() => this.styleLoaded, async () => {
        if (this.tierOnePinsAdded || !this.map || !this.styleLoaded) return;
        tierOnePinFeatures.forEach((pin) => {
          if (isNaN(pin.geometry.coordinates[0]) || isNaN(pin.geometry.coordinates[1])) console.log('COORDINATES NOT A NUMBER (map.ts t1)', pin.properties.id, JSON.stringify(pin.geometry.coordinates))
        })

        console.log('GETTING SOURCE')
        if (!this.map.getSource('tierOnePins')) {
          console.log('GOT SOURCE - ADDING SOURCE')
          this.map.addSource('tierOnePins', {
            type: 'geojson',
            data: {
              type: "FeatureCollection",
              features: tierOnePinFeatures
            },
          })
        }

        // Close up Tier 1 pins.
        const tierOneLayer: AnyLayer = {
          id: 'tierOnePinsClose',
          type: 'symbol',
          source: 'tierOnePins',
          minzoom: 10,
          paint: {
            'text-color': '#fff',
            'text-halo-width': 5,
            'text-halo-color': '#333e4d',
            'text-halo-blur': 0,
            'text-translate': [0, 40]
          },
          layout: {
            'icon-image': ['get', 'marker_image'],
            'icon-size': 0.65,
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-anchor': 'center',
            'text-field': ['get', 'rawTitle'],
            'text-variable-anchor': ['top'],
            'text-radial-offset': 0.5,
            'text-justify': 'auto',
            'text-font': [
              'Libre Franklin Bold',
              'Arial Unicode MS Bold'
            ]
          },
        }
        console.log('ADDING TIER 1 LAYER')
        this.map.addLayer(tierOneLayer)

        // Zoomed out Tier 1 pins.
        tierOneLayer.id = 'tierOnePinsFar'
        tierOneLayer.minzoom = 0
        tierOneLayer.maxzoom = 10
        if (tierOneLayer.paint) tierOneLayer.paint['text-translate'] = [0, 30]
        if (tierOneLayer.layout) tierOneLayer.layout['icon-size'] = 0.5
        this.map.addLayer(tierOneLayer)

        this.tierOnePinsAdded = true
      }, { immediate: true })
    },
    addMapPins(pinsFeatures: PinGeoJsonFeature[]) {
      watch(() => this.styleLoaded, async () => {
        if (this.pinsAdded || !this.map || !this.styleLoaded) return;

        pinsFeatures.forEach((pin) => {
          if (isNaN(pin.geometry.coordinates[0]) || isNaN(pin.geometry.coordinates[1])) console.log('COORDINATES NOT A NUMBER (map.ts pins)', pin.properties.id, JSON.stringify(pin.geometry.coordinates))
        })

        // check if there is already a pins source
        console.log("ADDING PINS TO MAP")
        // check if the map already has a pins source
        console.log('GETTING PINS SOURCE')
        if (!this.map.getSource('pin')) {
          console.log('GOT PINS SOURCE ADDING PINS')
          this.map.addSource('pins', {
            type: 'geojson',
            data: {
              type: "FeatureCollection",
              features: pinsFeatures
            },
            cluster: true,
            clusterMaxZoom: 10,
            clusterRadius: 50
          })
        }

        const image = await this.loadImage('/pin.png').catch(() => {
          console.error("error loading pins image")
          return
        });

        if (!image) return;
        this.addImage('pin', image);

        // just in case the tier one pins haven't been added yet
        if (!this.map.getLayer('tierOnePinsClose')) {
          await new Promise<void>((resolve) => {
            const tmpInterval = window.setInterval(() => {
              if (!this.map?.getLayer('tierOnePinsClose')) return
              window.clearInterval(tmpInterval)
              resolve()
            }, 100)
          })
        }

        // Close up pins.
        const pinsLayer: AnyLayer = {
          id: 'pinsClose',
          type: 'symbol',
          source: 'pins',
          minzoom: 8,
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': ['get', 'marker_image'],
            'icon-size': 0.75,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-anchor': 'bottom',
          },
        }
        console.log('ADDING PINS LAYER')
        this.map.addLayer(pinsLayer, 'tierOnePinsClose')

        // Far away pins.
        pinsLayer.id = 'pinsFar'
        pinsLayer.minzoom = 3
        pinsLayer.maxzoom = 8
        if (pinsLayer.layout) pinsLayer.layout['icon-size'] = 0.5
        this.map.addLayer(pinsLayer, 'pinsClose')

        // Very far away pins (look like clusters).
        this.map.addLayer({
          id: 'pinsVeryFar-count',
          type: 'symbol',
          source: 'pins',
          minzoom: 0,
          maxzoom: 3,
          paint: {
            'text-color': 'white',
          },
          layout: {
            'text-field': "1",
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        }, 'pinsClose')
        this.map.addLayer({
          id: 'pinsVeryFar',
          type: 'circle',
          source: 'pins',
          minzoom: 0,
          maxzoom: 3,
          paint: {
            'circle-color': '#D30000',
            'circle-radius': 20,
          },
        }, 'pinsVeryFar-count')

        console.log('ADDING CLUSTER COUNTS')
        this.map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'pins',
          filter: ['has', 'point_count'],
          paint: {
            'text-color': 'white',
          },
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        }, 'pinsClose')

        console.log('ADDING CLUSTERS')
        this.map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'pins',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': '#D30000',
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        }, 'cluster-count')

        this.pinsAdded = true
        console.log('FINISHED ADDING ALL PINS')
      }, { immediate: true })
    },
    addOnce(event: any, func: ((ev: unknown & mapboxgl.EventData) => void), layers?: string[] | string) {
      if (!this.map) return;
      if (layers) {
        this.map.once(event, layers, func);
        return;
      }
      this.map.once(event, func);
    },
    // Horrible hack to get around overloading parameters
    addOn(event: any, func: ((ev: unknown & mapboxgl.EventData) => void), layers?: string[] | string) {
      if (!this.map) return;
      if (layers) {
        this.map.on(event, layers, func);
        return;
      }
      this.map.on(event, func);
    },
    addOff(event: any, func: ((ev: unknown & mapboxgl.EventData) => void), layers?: string[] | string) {
      if (!this.map) return;
      if (layers) {
        this.map.off(event, layers, func);
        return
      }
      this.map.off(event, func);
    },
    moveLayer(id: string, beforeId?: string) {
      if (!this.map) return;
      this.map.moveLayer(id, beforeId);
    },

    getUserLocation(router: Router) {
      const { coords, locatedAt, error, pause } = useGeolocation()
      console.log('GET USERS LOCATION', coords, locatedAt, error)
      this.userLocationRetrieving = true

      const jsonpFallback = () => {

        this.userLocationRetrieving = false
        // pause so if it does eventually get a location it doesn't trigger the watcher
        pause()

        jsonp('https://fullfatthings-mylocation.appspot.com/', undefined, (err, data: { 'X-Appengine-Citylatlong': string }) => {
          if (data['X-Appengine-Citylatlong']) {
            if (err) throw err
            const latlong = data['X-Appengine-Citylatlong'].split(',')
            this.userLocation = { lat: parseFloat(latlong[0]), lng: parseFloat(latlong[1]) } as Coordinates
            this.setUserMarker(this.userLocation)
            if (this.userLocationRequested) this.goToUserLocation(router)
          }
        })
      }

      const geolocateTimeout = window.setTimeout(() => {
        console.log("Geolocation timeout, falling back to jsonp")
        jsonpFallback()
      }, 5000)

      watch(() => coords.value, () => {
        if (!coords.value.accuracy) return
        this.userLocationRetrieving = false
        window.clearTimeout(geolocateTimeout)

        if (!this.userLocation || haversineDistance(this.userLocation, { lat: coords.value.latitude, lng: coords.value.longitude } as Coordinates) > 0.01) {
          this.userLocation = { lat: coords.value.latitude, lng: coords.value.longitude } as Coordinates
          this.setUserMarker(this.userLocation)
          if (this.userLocationRequested) this.goToUserLocation(router)
        }
      })

      watch(() => error.value, () => {
        if (!error.value) return
        console.log("Geolocation error", error.value.code, error.value.message, "falling back to jsonp")
        window.clearTimeout(geolocateTimeout)
        this.userLocationRetrieving = false
        jsonpFallback()
      })
    },

    goToUserLocation(router: Router) {
      console.log('GO TO USERS LOCATION')
      this.userLocationRequested = true

      if (this.userLocation) {
        // Only go to users location if we're not close to it.
        const mapCentre = this.map?.getCenter()

        if (haversineDistance(this.userLocation, mapCentre as Coordinates) > 0.01) {
          const tourStore = useTourStore()
          const languagesStore = useLanguagesStore()
          const pinsStore = usePinsStore()
          tourStore.stopTour()
          pinsStore.clearPinSelected()
          router.replace({ name: `geoView:${languagesStore.getActiveLanguage().id}`, hash: `#${this.userLocation.lat},${this.userLocation.lng}` })
        }
      }
      else {
        this.getUserLocation(router)
      }
    },

    setUserMarker(coords: Coordinates) {
      this.clearUserMarker()

      // Create a DOM element for the marker.
      const el = document.createElement('div')
      el.className = 'userMarker'

      if (!this.map) return
      new mapboxgl.Marker({
        element: el
      })
        .setLngLat(coords)
        .addTo(this.map)
    },
    clearUserMarker() {
      const marker = document.querySelector('.userMarker')
      if (marker) marker.remove()
    },

    setPulsingMarker(pin: Pin) {
      this.clearPulsingMarker()

      if (pin.type === 'article' || (pin.tier === 1 && pin.type !== 'event')) {
        return
      }

      // Create a DOM element for the marker.
      const el = document.createElement('div')
      el.className = 'marker'

      const elPulser = document.createElement('div')
      elPulser.className = 'marker-pulser animated'
      el.appendChild(elPulser)

      if (!this.map) return
      new mapboxgl.Marker({
        element: el,
        offset: [0, -40]
      })
        .setLngLat(pin.coordinates)
        .addTo(this.map)
    },
    clearPulsingMarker() {
      const marker = document.querySelector('.marker')
      if (marker) marker.remove()
    },

    calculateCloseTier1(coordinates: Coordinates): Pin | undefined {
      if (!this.map) return

      const tier1Pins = usePinsStore().getTier1EventPins()
      const closestTier1Event = sortPinsByProximity(tier1Pins, coordinates)[0] as Pin

      if (!closestTier1Event) return

      const closestTier1PinCoords = {
        lat: closestTier1Event.coordinates[1],
        lng: closestTier1Event.coordinates[0]
      }

      if (isDistanceLessThanRange(closestTier1PinCoords, this.map.getCenter(), 50)) {
        console.log("CLOSE TO A TIER 1 PIN", closestTier1Event)
        return closestTier1Event
      }
    },
    flyTo(center: [number, number], options?: FlyToOptions) {
      const defaults: FlyToOptions = {
        zoom: Math.max(13, (this.map?.getZoom() || 0)),
        hideMobile: true,
        onMoveEndCallback: undefined,
        landingMobileBreakpoint: "middle",
        offset: [0, 0]
      }

      const { zoom, hideMobile, onMoveEndCallback, landingMobileBreakpoint, offset } = Object.assign(defaults, options)

      const mobileSlideoutStore = useMobileSlideoutStore()
      if (hideMobile) mobileSlideoutStore.close();

      console.log('FLYING TO COORDINATE', center, zoom)
      if (!this.map) return;
      this.map.flyTo({ center, zoom, offset });
      this.map.once('moveend', (e) => {
        if (onMoveEndCallback) onMoveEndCallback(e)
        if (hideMobile) {
          // Handle all the landing mobile states
          if (landingMobileBreakpoint === 'top') mobileSlideoutStore.open();
          if (landingMobileBreakpoint === 'middle') mobileSlideoutStore.partialOpen();
          if (landingMobileBreakpoint === 'bottom') mobileSlideoutStore.close();
        }
      })
    },
    flyToGeoPoint(feature: mapboxgl.MapboxGeoJSONFeature, options?: FlyToOptions) {
      console.log('FLYING TO GEO POINT', feature)
      if (!this.map) return;
      if (feature.geometry.type !== "Point") return;

      this.flyTo(feature.geometry.coordinates as [number, number], options)
    },
    flyToCluster(feature: mapboxgl.MapboxGeoJSONFeature, options?: FlyToOptions) {
      const defaults: FlyToOptions = {
        zoom: (this.map?.getZoom() || 0) + 2,
      }

      const mergedOptions = Object.assign(defaults, options)

      console.log('FLYING TO CLUSTER', feature)
      if (!this.map) return;
      this.flyToGeoPoint(feature, mergedOptions)
    },
    flyToPin(pin: Pin, options?: FlyToOptions) {
      console.log('FLYING TO PIN', pin.id)
      if (pin.coordinates[0] == 0 && pin.coordinates[1] == 0) return;
      this.flyTo(pin.coordinates, options)
    },
    flyToPinId(pinId: number, options?: FlyToOptions) {
      console.log('FLYING TO PIN ID', pinId, 'type: ', (typeof pinId))
      const pin = usePinsStore().pinsMap.get(pinId);
      console.log('FOUND PIN: ', pin)
      if (!pin) return;
      this.flyToPin(pin, options)
    },
    findCloseTier1Pin() {
      if (!this.map) return;

      const newCloseTier1Pin = this.calculateCloseTier1(this.map.getCenter())

      if (newCloseTier1Pin) {
        if (!this.closeTier1Pin || (newCloseTier1Pin.id !== this.closeTier1Pin.id)) {
          this.closeTier1Pin = newCloseTier1Pin;
        }
        return true;
      }

      return false
    },
    updateCursor(type: string) {
      if (!this.map) return;
      this.map.getCanvas().style.cursor = type;
    },
    removeMap() {
      if (!this.map) return;
      this.map.remove();
      this.map = undefined;
    },
    mapStoppedMoving() {
      this.mapIsMoving = false;
      console.log("The map has stopped moving")
    },
    mapStartedMoving() {
      this.mapIsMoving = true;
      console.log("The map is moving")
    },
    /**
    * Generates an interrupt function that will call the callback function
    * @returns A function that will remove the interrupt listeners
    **/
    onInterrupt(callback: Function) {
      console.log("GENERATING INTERRUPTS FOR FUNC", callback)
      const interruptListeners = new Array<{ type: string, func: ((ev: any) => void) }>();
      if (!this.map) return;

      interruptStrings.forEach((interrupt) => {
        const listenerFunc = () => {
          console.log("INTERRUPTED", interrupt, callback)
          callback();
          off();
        }

        if (!this.map) return;
        this.addOnce(interrupt, listenerFunc);
        interruptListeners.push({ type: interrupt, func: listenerFunc })
      });

      const off = () => {
        interruptListeners.forEach((interrupt) => {
          if (!this.map) return;
          this.map.off(interrupt.type, interrupt.func);
        });
      }

      return off
    },

    resize() {
      if (!this.map) return;
      this.map.resize();
    },
    currentCenterGeoViewRoute() {
      const languagesStore = useLanguagesStore();
      const geoViewRoute = ref<RouteLocationRaw>()

      watchEffect(() => {
        const coords = this.map?.getCenter()
        if (!coords) return
        geoViewRoute.value = {
          name: `geoView:${languagesStore.getActiveLanguage().id}`,
          hash: `#${coords.lat},${coords.lng}`
        }
      })

      return geoViewRoute
    },
    addImage(
      name: string,
      image:
        | HTMLImageElement
        | ArrayBufferView
        | { width: number; height: number; data: Uint8Array | Uint8ClampedArray }
        | ImageData
        | ImageBitmap,
      options?: {
        pixelRatio?: number | undefined;
        sdf?: boolean | undefined;
        stretchX?: [number, number][] | undefined;
        stretchY?: [number, number][] | undefined;
        content?: [number, number, number, number] | undefined;
      },
    ): void {
      if (!this.map) return;
      this.map.addImage(name, image, options)
    },


  }
})

const appMapDraggedEvent = (mapStore: MapStore, tourStore: TourStore) => {
  if (!mapStore.map) return;
  mapStore.addOn('dragend', () => {
    console.log('MAP DRAG END')
    // No snapping if tour is playing or is spinning idly
    if (!mapStore.map) return;
    if (tourStore.tourPlaying || mapStore.mapSpinning) return
    mapStore.mapMoved = true
  })

  mapStore.addOn('zoomend', () => {
    console.log('MAP ZOOM END')
    // No snapping if tour is playing or is spinning idly
    if (!mapStore.map) return;
    if (tourStore.tourPlaying || mapStore.mapSpinning) return
    mapStore.mapMoved = true
  })
}

const addMapCursorEvents = (mapStore: MapStore) => {
  mapStore.addOn('mouseenter', () => {
    mapStore.updateCursor('pointer')
  }, ['clusters', 'pinsClose', 'pinsVeryFar', 'pinsFar', 'tierOnePinsClose', 'tierOnePinsFar'])

  mapStore.addOn('mouseleave', () => {
    mapStore.updateCursor('')
  }, ['clusters', 'pinsClose', 'pinsVeryFar', 'pinsFar', 'tierOnePinsClose', 'tierOnePinsFar'])
}

const addMapClusterClickEvent = (mapStore: MapStore) => {
  mapStore.addOn('click', (event) => {
    if (!mapStore.map) return;

    const features = mapStore.map.queryRenderedFeatures(event.point, {
      layers: ['clusters']
    });

    if (!features.length || !features[0].properties) return;

    const geometry = features[0].geometry;

    if (geometry.type === "Point") {
      mapStore.flyToCluster(features[0])
    }
  }, 'clusters')
}

const addMapPinClickEvent = (map: mapboxgl.Map, pinsStore: ReturnType<typeof usePinsStore>, mapStore: MapStore) => {
  mapStore.addOn('click', async (event) => {
    const layers = []
    if (pinsStore.tierOnePinsFeatures.length) layers.push('tierOnePinsClose', 'tierOnePinsFar')
    if (pinsStore.pinsFeatures.length) layers.push('pinsClose', 'pinsFar', 'pinsVeryFar')

    const features = map.queryRenderedFeatures(event.point, {
      layers: layers
    });
    console.log('ALL FEATURES', features)

    if (!features.length || !features[0].properties || !features[0].properties.id) return

    Fathom.trackEvent(`Foodmark pin clicked: ${features[0].properties.id}`)

    pinsStore.newPinSelected(features[0].properties.id)
    mapStore.mapStartedMoving();

    const geometry = features[0].geometry;
    if (geometry.type === "Point") {
      mapStore.flyToGeoPoint(features[0])
    }

    const interruptsOff = mapStore.onInterrupt(() => {
      mapStore.mapIsMoving = false;
    });

    // wait for idle
    mapStore.addOnce('idle', () => {
      // if the map has stopped before the idle event,
      // an interrupt has happened
      if (!mapStore.mapIsMoving) return;
      mapStore.mapStoppedMoving()
      if (interruptsOff) interruptsOff();
    })

    if (interruptsOff) interruptsOff();
  }, ['pinsClose', 'pinsFar', 'pinsVeryFar', 'tierOnePinsClose', 'tierOnePinsFar'])
}
