<template>
  <template v-if="supportedCheck()">
    <button @click="shares[type].func()" class="share-button rounded-full bg-mid-grey w-[34px] h-[34px] shadow flex items-center justify-center hover:bg-coca-cola-red hover:text-white">
      <img v-if="shares[type].hasImage" class="my-0 w-4 h-4" @click="shares[type].func()" :src="imageUrl" :alt="type" />
      <span v-else class="font-bold">&hellip;</span>
    </button>
  </template>
</template>

<style>
.share-button:hover>img {
  filter: brightness(0) invert(1);
}
</style>


<script setup lang="ts">
import { SocialButtonType, type StoryResponse } from '@/types';
import { type UseShareOptions, useShare } from '@vueuse/core';
import { ref } from 'vue';

const props = defineProps<{
  content: StoryResponse
  type: SocialButtonType
}>()

const shareOptions = ref<UseShareOptions>({
  title: props.content.title,
  text: props.content.subTitle,
  url: props.content.url,
})

const { isSupported, share } = useShare(shareOptions)

const supportedCheck = () => {
  // If you want to check the support functionality, comment this block
  if (import.meta.env.DEV) {
    return true;
  }

  switch (props.type) {
    case SocialButtonType.WHATSAPP:
      return navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/iPhone|iPad|iPod/i);
    case SocialButtonType.SHAREAPI:
      return isSupported.value
    default:
      return true;
  }
}

const imageUrl = new URL(`../assets/images/${props.type}-social.svg`, import.meta.url).href
let decodedText = document.createElement("textarea");
decodedText.innerHTML = props.content.title;
const text = props.content.title ? `${decodedText.value} ` : ''

const shares = {
  [SocialButtonType.X]: {
    hasImage: true,
    func: () => {
      window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}${encodeURIComponent(window.location.href)}&hashtags=Foodmarks`, '_blank')
    },
  },
  [SocialButtonType.FACEBOOK]: {
    hasImage: true,
    func: () => {
      const width = 650
      const height = 420

      const left = (window.screen.width / 2) - ((width / 2) + 10);
      const top = (window.screen.height / 2) - ((height / 2) + 50);

      const newWindow = window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`,
        '',
        `left=${left},top=${top},width=${width},height=${height},personalbar=0,toolbar=0,scrollbars=0,resizable=0`
      )

      if (!newWindow) return
      newWindow.focus()
    },
  },
  [SocialButtonType.WHATSAPP]: {
    hasImage: true,
    func: () => {
      window.open(`whatsapp://send?text=${encodeURIComponent(text)}${encodeURIComponent(window.location.href)}`)
    },
  },
  [SocialButtonType.SHAREAPI]: {
    hasImage: false,
    func: () => {
      share();
    },
  }
}

</script>
