<template>
  <button
    class="fizzy-button select-none cursor-pointer
    h-10 overflow-hidden rounded-md
    flex items-center justify-items-center
    border-2 box-border
    font-bold text-[19px] font-sansTitle
    hover:text-white hover:border-coca-cola-red-alt
    bg-coca-cola-red text-white border-coca-cola-red hover:bg-coca-cola-red-alt px-3 isRed"
    aria-label="Go to my location">
    <slot />
  </button>
</template>

<style>
.fizzy-button:hover>svg, .fizzy-button.isRed>svg {
  filter: brightness(0) invert(1);
}
</style>
