<template>
  <div v-if="!isMobile || mobileSlideoutStore.ready" class="space-y-6 py-6 px-[1.88rem]">
    <div class="flex flex-col items-start space-y-6">
      <PinImage v-if="ready && delayedTourIndex !== undefined && tourStore.tourPins[delayedTourIndex]" :size="PinSize.Large"
        :pin="tourStore.tourPins[delayedTourIndex]" />
      <div class="welcome-body prose" v-html="content?.body" v-if="ready"></div>
      <template v-else>
        <ContentLoading />
      </template>
    </div>

    <h1 class="leading-[3.2rem] text-3xl font-serif">
      <div v-if="otherFoodmarkCount != '0'" v-html="appStringsStore.get('homeFoodmarkCountText').replace('[COUNT]', `<span class='text-coca-cola-red'>${otherFoodmarkCount}</span>`)" />
      <div v-else v-html="appStringsStore.get('homeFoodmarkCountText').replace('[COUNT]', loadingDots)" />
      <div v-if="iconicFoodmarkCount != '0'" v-html="appStringsStore.get('homeIconicFoodmarkCountText').replace('[COUNT]', `<span class='text-coca-cola-red'>${iconicFoodmarkCount}</span>`)" />
      <div v-else v-html="appStringsStore.get('homeIconicFoodmarkCountText').replace('[COUNT]', loadingDots)" />
    </h1>
    <div v-if="ready" class="grid grid-cols-1 md:grid-cols-2 lg:flex gap-2.5">
      <LocationButton v-if="!mapStore.mapCreationError" @click="mapStore.goToUserLocation(router)" :showLabel="true" :red="true" />
      <TourStatusButton v-if="tourStore.tourPins.length" @click="tourStore.toggleTourPlaying" :sidebar="true" />
    </div>
    <StoriesSearch />

    <div v-for="tier in [1, 2]" :key="tier" class="mb-7">
      <h2 v-if="!teasersByType || (teasersByType && teasersByType[tier-1])" class="border-b-[3px] mb-3 border-timeout-red font-serif text-2xl leading-[1.25em]" v-html="appStringsStore.get('tier' + tier + 'Title')"></h2>
      <FeaturedCollection v-if="tier == 1" :stories="teasersByType && teasersByType[tier-1] ? teasersByType[tier-1] : false" />
      <StoryCollection v-else :stories="teasersByType && teasersByType[tier-1] ? teasersByType[tier-1] : false" />
    </div>

    <template v-if="promotedPins?.length">
    <div v-for="(teasers, index) in promotedPins" :key="index" class="my-7">
      <template v-if="teasers.length">
        <h2 class="border-b-[3px] mb-3 border-timeout-red text-base-grey text-2xl font-serif leading-[1.25em]"
            v-html="appStringsStore.get('readMore')"></h2>
        <StoryCollection :stories="teasers" />
      </template>
    </div>
    </template>
  </div>
</template>

<style>
.welcome-body>p {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.5rem;
}
</style>

<script setup lang="ts">
import { useFetch } from '@vueuse/core';
import { onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'
import { PinSize, type ContentResponse, type Pin, type UseFetchResponse } from '@/types'
import StoryCollection from '@/components/StoryCollection.vue';
import StoriesSearch from '@/components/StoriesSearch.vue';
import useTeaserGroups from "@/composables/useTeaserGroups";
import { usePinsStore } from "@/stores/pins";
import { useAppStringsStore } from '@/stores/appStrings'
import { Endpoint, useApiUrl } from "@/composables/useApiUrl";
import ContentLoading from "@/components/ContentLoading.vue";
import { useAppStateStore } from "@/stores/appState";
import { useMapStore } from '@/stores/map';
import PinImage from '@/components/PinImage.vue';
import { useTourStore } from "@/stores/tour";
import TourStatusButton from "@/components/TourStatusButton.vue";
import LocationButton from "@/components/LocationButton.vue";
import FeaturedCollection from "@/components/FeaturedCollection.vue";
import { useRoute, useRouter } from "vue-router";
import useIsMobile from "@/composables/useIsMobile";
import {useMobileSlideoutStore} from "@/stores/mobileSlideout";

const ready = ref(false)
const pinsStore = usePinsStore()
const teasersByType = ref<Pin[][]>()
const promotedPins = ref<Pin[][]>()
const appStringsStore = useAppStringsStore()
const appStateStore = useAppStateStore()
const iconicFoodmarkCount = ref("0")
const otherFoodmarkCount = ref("0")
const content = ref<ContentResponse>()
const mapStore = useMapStore()
const tourStore = useTourStore()
const route = useRoute()
const router = useRouter()
const isMobile = useIsMobile()
const mobileSlideoutStore = useMobileSlideoutStore()

const loadingDots = '<div class=\'inline-flex mx-2 space-x-0.5 justify-center items-center\'>\n' +
  '  <div class=\'h-1.5 w-1.5 bg-coca-cola-red rounded-full animate-bounce [animation-delay:-0.3s]\'></div>\n' +
  '  <div class=\'h-1.5 w-1.5 bg-coca-cola-red rounded-full animate-bounce [animation-delay:-0.15s]\'></div>\n' +
  '  <div class=\'h-1.5 w-1.5 bg-coca-cola-red rounded-full animate-bounce\'></div>\n' +
  '</div>'

// Create the delayed tour store index so that the tour image changes halfway
// through the flying animation instead of instantly
const delayedTourIndex = ref()
let tourIndexTimeout: number | undefined

watch(() => tourStore.tourIndex, () => {
  if (tourStore.tourPins.length === 0) return
  tourIndexTimeout = window.setTimeout(() => {
    delayedTourIndex.value = tourStore.tourIndex
  }, 2000)
}, { immediate: true })

onUnmounted(() => {
  // clear the timeout if changing page, just don't want it to error, not sure
  // if this is an issue in vue but it definitely is in react
  tourIndexTimeout && window.clearTimeout(tourIndexTimeout)
})

const {
  data: pageContent,
  isFinished: pageLoaded
}: UseFetchResponse<[ContentResponse]> =
  useFetch<[ContentResponse]>(useApiUrl(Endpoint.HomePage, { additionalPath: import.meta.env['VITE_HOMEPAGE_NID'] ?? "1999" }))
    .get().json()


// generates the numbers for the foodmark count
watchEffect(() => {
  const tierOneCount = pinsStore.tierOneCount
  iconicFoodmarkCount.value = tierOneCount.toLocaleString('en-us')
  otherFoodmarkCount.value = pinsStore.pinsFeatures.length.toLocaleString('en-us')
})

watchEffect(() => {
  if (pageLoaded.value) {
    if (!pageContent.value) return
    content.value = pageContent.value[0]
    const personalisedPins = pinsStore.sortPinsForUser(Array.from(pinsStore.pinsMap.values()))
    const sortedGroupedTeasers = useTeaserGroups(personalisedPins)
    teasersByType.value = sortedGroupedTeasers

    // Get the promoted pins by filtering the personalised pins.
    const hompagePins = personalisedPins.filter(pin => pin.homepage === 1)
    const sortedGroupedHomepageTeasers = useTeaserGroups(hompagePins, false)

    // Remove tier 1 events from sortedGroupedTeasers.
    if (sortedGroupedHomepageTeasers?.[0]) sortedGroupedHomepageTeasers[0] = [];
    promotedPins.value = sortedGroupedHomepageTeasers
    console.log('PROMOTED PINS', promotedPins.value)

    ready.value = true

    if (route.name) {
      appStateStore.setHrefLangTags(content.value?.availableTranslations, route.name.toString(), router)
    }

    // Set the Meta tags.
    if (content.value.metatags) {
      content.value.metatags.canonical_url = window.location.href
      appStateStore.setMetaTags(content.value.metatags)
    }
  }
})

onMounted(() => {
  // prevent the input from being focused on page load causing the keyboard
  // to pop up on mobile
  document.body.setAttribute("tabindex", "-1")
  document.body.focus()

  if (isMobile) {
    mobileSlideoutStore.open()
  }

  watch(() => appStateStore.appLoading, () => {
    if (!appStateStore.appLoading && tourStore.nextTourWelcome) {
      tourStore.reset()

      // Only reset and start the tour if this is the first page or logo was clicked.
      if (appStateStore.pageCount === 0) {
        mapStore.reset(false)
        mapStore.startWelcomeTour()
      }
    }
    tourStore.nextTourWelcome = true
  }, { immediate: true })
})

</script>

