<template>
  <RouterLink :to="{
      name: `stories:${languagesStore.getActiveLanguage().id}`,
      params: { alias: pin.alias }
    }"
    :key="pin.id"
    class="rounded-md overflow-hidden block bg-light-grey-alt">
    <div class="relative">
      <img class="w-full" :width="pinData[size].width" :height="pinData[size].height" :src="size == PinSize.Large ? pin.imageLarge : pin.image" alt="">
      <div
        class="absolute top-0 left-0 right-0 bottom-0 h-full"
        :class="{'[background:linear-gradient(180deg,rgba(0,0,0,0)_0%,rgba(0,0,0,0)_50%,rgba(0,0,0,0.03)_50.1%,rgba(0,0,0,0.5)_100%)]': size == PinSize.Medium}">
        <div class="flex flex-col justify-end h-full">
          <div
            :style="{
              fontSize: pinData[size].fontSize,
              lineHeight: pinData[size].lineHeight,
              padding: pinData[size].padding,
            }" class="prose font-bold font-sansTitle text-white m-0 [text-shadow:_0_0_6px_rgb(0_0_0_/_60%)]"
          :class="{
            '[background:linear-gradient(180deg,rgba(0,0,0,0)_0%,rgba(0,0,0,0.25)_33px,rgba(0,0,0,0.5)_66px,rgba(0,0,0,0.5)_100%)]': size != PinSize.Medium
          }">
            <div v-html="pin.title" />
            <div v-if="size == PinSize.Large && pin.subTitle" v-html="pin.subTitle" :style="{fontSize: pinData[size].subTitleSize}" />
          </div>
        </div>
      </div>
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import { type Pin, PinSize } from '@/types'
import { useLanguagesStore } from '@/stores/languages';

const languagesStore = useLanguagesStore()

defineProps<{
  pin: Pin
  size: PinSize
}>()

const pinData = {
  [PinSize.Small]: {
    fontSize: '1rem',
    subTitleSize: '1rem',
    lineHeight: '1.2em',
    padding: '66px 0.5rem 0.5rem',
    width: 132,
    height: 168
  },
  [PinSize.Medium]: {
    fontSize: '1.5rem',
    subTitleSize: '1.5rem',
    lineHeight: '1em',
    padding: '0.75rem',
    width: 212,
    height: 151
  },
  [PinSize.Large]: {
    fontSize: '2.25rem',
    lineHeight: '2.25rem',
    padding: '1.56rem',
    subTitleSize: '1.6rem',
    width: 722,
    height: 512
  }
}
</script>
