import { defineStore } from 'pinia';
import type { Pin } from "@/types";
import { usePinsStore } from "@/stores/pins";
import { useMapStore } from './map';
import { useMobileSlideoutStore } from './mobileSlideout';
import { watchEffect } from 'vue';
import useIsMobile from '@/composables/useIsMobile';
import { useLanguagesStore } from "@/stores/languages";
import { type Router } from 'vue-router';

const globalTourVisitTime = 10000; // Measured in milliseconds

export const useTourStore = defineStore('tour', {
  state: () => ({
    tourPins: [] as Pin[],
    tourPlaying: false,
    tourIndex: undefined as number | undefined,
    // The tour time is used to determine whether to displaying the
    // "Flying in x seconds" message
    tourTimeRemaining: undefined as number | undefined, // The time the current pin has left
    tourTimerId: undefined as number | undefined,
    disableMapInterrupts: undefined as Function | undefined,
    nextTourWelcome: false,
    tourCount: 0
  }),
  actions: {
    buildTourGlobalPlaylist() {
      // TODO - Sort by upcoming followed by expired
      watchEffect(() => {
        this.tourPins = [...usePinsStore().pinsMap.values()]
          .filter((pin: Pin) => pin.tier === 1 && pin.type === 'event')

        console.log('Tour Global Playlist Rebuild', this.tourPins?.map((pin: Pin) => pin.id))
      })
    },
    toggleTourPlaying(skipCountdown: boolean = false, router? :Router) {
      // Route to the homepage if we're not already there.
      if (!this.tourPlaying && router && !router?.currentRoute?.value.name?.toString().startsWith('home:')) {
        const languagesStore = useLanguagesStore()
        this.nextTourWelcome = false
        router.push({ name: `home:${languagesStore.getActiveLanguage().id}` })
        skipCountdown = true
      }

      this.tourPlaying ? this.stopTour() : this.playTour(skipCountdown)
    },
    playTour(skipCountdown: boolean = false) {
      this.tourCount++
      const mapStore = useMapStore()
      const sidebarInterrupts = ['click', 'scroll', 'touchstart', 'touchmove', 'touchend']
      // There are two sidebars, mobile and desktop
      const sidebars = [...document.getElementsByClassName('sidebar-contents')]
      console.log("PLAYING TOUR")

      const interruptFunc = () => {
        if (this.tourPlaying) {
          this.stopTour()
        }

        // disable further interrupts
        sidebarInterrupts.forEach((event) => {
          sidebars.forEach(sb => sb.removeEventListener(event, interruptFunc))
        })

        window.onscroll = null

        // disable further mapbox interrupts
        if (this.disableMapInterrupts) this.disableMapInterrupts()
      }

      // wait 50ms to add interrupts, prevents clicking on the sidebar button
      // causing a tour stop
      setTimeout(() => {
        sidebarInterrupts.forEach((event) => {
          console.log("GENERATING SIDEBAR INTERRUPT", event)
          // mobile slideout
          sidebars.forEach(sb => sb.addEventListener(event, interruptFunc, { passive: true }))
          // desktop slideout
          window.onscroll = interruptFunc
        })
      }, 100)

      this.disableMapInterrupts = mapStore.onInterrupt(interruptFunc)

      this.tourPlaying = true
      this.flyToNextPin(skipCountdown)
    },
    stopTour() {
      console.log("STOPPING TOUR")
      if (this.disableMapInterrupts) this.disableMapInterrupts()
      this.tourPlaying = false
      this.tourTimeRemaining = undefined;
    },
    reset() {
      this.tourIndex = undefined
      console.log("resetting tour")
    },
    async countdown(ms: number): Promise<void> {
      this.tourTimeRemaining = ms

      window.clearInterval(this.tourTimerId)

      return new Promise((resolve) => {
        this.tourTimerId = window.setInterval(() => {
          if (!this.tourTimeRemaining) {
            window.clearInterval(this.tourTimerId)
            // set it to undefined as it can be 0
            this.tourTimeRemaining = undefined;
            resolve()
            return
          }
          this.tourTimeRemaining -= 1000
          if (this.tourTimeRemaining > 0) return
          this.tourTimeRemaining = undefined
          window.clearInterval(this.tourTimerId)
          resolve()
        }, 1000)
      })
    },
    async flyToNextPin(skipCountdown: boolean = false) {
      if (!this.tourPlaying) return

      if (!skipCountdown) {
        await this.countdown(globalTourVisitTime)
      }

      // add guards after the countdown has finished
      if (!this.tourPlaying) return

      // only set the tour index after the countdown has finished
      if (this.tourIndex === undefined) this.tourIndex = -1
      if (this.tourIndex >= this.tourPins.length - 1) this.tourIndex = -1
      this.tourIndex = (this.tourIndex || 0) + 1

      const pin: Pin = this.tourPins[this.tourIndex]
      const mapStore = useMapStore()

      if (!pin) {
        console.warn('No tour pin found', this.tourPins, this.tourIndex)
        return
      }

      const mobileSlideoutStore = useMobileSlideoutStore()
      mobileSlideoutStore.scrollToTop();

      const isMobile = useIsMobile()

      console.log('Tour Fly To Pin', this.tourIndex, pin)
      // Stop the map spinnning when the tour lands on the first pin
      if (mapStore.mapSpinning) mapStore.stopSpinning()
      // offset on mobile so the pin can be seen above the partially open slideout
      const screenHeight = window.innerHeight
      const mobileOffset = screenHeight * 0.000175
      const coords: [number, number] = [pin.coordinates[0], pin.coordinates[1] - (isMobile.value ? mobileOffset : 0)]
      const currentTourIndex = this.tourIndex
      mapStore.flyTo(coords, {
        zoom: 10,
        onMoveEndCallback: async () => {
          if (currentTourIndex !== this.tourIndex) return
          if (!this.tourPlaying) return
          this.flyToNextPin()
        },
        landingMobileBreakpoint: "middle",
      })
    }
  }
})
