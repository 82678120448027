  <template>
  <div class="gap-2 grid grid-cols-1 lg:grid-cols-2">
    <template v-if="props.stories === false">
      <div v-for="i in 6" :key="i" class="animate-pulse bg-light-grey-alt w-full aspect-[10/7] rounded-md" />
    </template>
    <template v-else>
      <template v-for="story in props.stories" :key="story.id">
        <PinImage :size="PinSize.Medium" :pin="story" />
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { PinSize, type Pin } from '@/types'
import PinImage from '@/components/PinImage.vue'

const props = defineProps<{
  stories: Pin[] | false
}>()
</script>
