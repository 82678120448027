<template>
  <div class="relative w-full aspect-[4/3]" ref="coverImageWrapper">
    <div class="overflow-hidden w-full h-full bg-no-repeat bg-cover bg-center"
      :style="{
        backgroundImage: image ? `url(${image})` : 'none',
      }">
      <div
        class="absolute top-0 left-0 right-0 bottom-0 h-full"
        style="background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.03) 50.1%, rgba(0,0,0,0.5) 100%);">
        <h1 class="text-[1.75rem] text-white mb-0 leading-[120%] flex flex-col justify-end h-full font-bold font-sansTitle p-6 [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
          <span class="block" v-html="title" />
          <span v-if="subTitle" class="text-[1.5rem] mt-0 leading-[120%]" v-html="subTitle" />
        </h1>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from "vue"
import { useThrottleFn } from '@vueuse/core'

defineProps<{
  image?: string;
  title?: string;
  subTitle?: string;
}>()

const pastCoverImage = ref<boolean>(false)
const coverImageWrapper = ref<HTMLDivElement>()

onMounted(() => {
  window.addEventListener('scroll', scroll)
  document.getElementById('mobile-slideout')?.addEventListener('scroll', scroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', scroll)
  document.getElementById('mobile-slideout')?.removeEventListener('scroll', scroll)
})

const setCSSVars = (name: string, value: string) => {
  document.documentElement.style.setProperty(name, value)
}

const scroll = useThrottleFn((event: any) => {
  const scrollAmount = event.target?.scrollTop ?? event?.target?.scrollingElement?.scrollTop ?? 0
  const coverImageEnd = (coverImageWrapper.value?.offsetHeight ?? 0) - 48
  pastCoverImage.value = scrollAmount > coverImageEnd
}, 10)

watch(() => pastCoverImage.value, (newVal) => {
  setCSSVars('--closeButtonDynamicBackground', newVal ? '#1A1A1A' : '#fff')
  setCSSVars('--closeButtonDynamicText', newVal ? '#fff' : '#1A1A1A')
})

</script>
