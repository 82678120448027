<template>
  <div class="space-y-6 py-6 px-[1.88rem] lg:pt-10">
    <h1 class="sr-only" v-html="appStringsStore.get('searchButton')" />
    <StoriesSearch />
    <div class="space-y-3 mb-2 overflow-x-hidden">
      <template v-if="searchStore.contentSuggestions.length">
        <div v-for="(teasers, index) in teasersByType" :key="index" class="mb-7">
          <template v-if="teasers.length">
            <h2 class="border-b-[3px] mb-3 border-timeout-red font-serif text-2xl leading-[1.25em]"
                v-html="appStringsStore.get('tier' + (teasers[0]?.tier == 1 ? 1 : 2) + 'Title')"></h2>
            <FeaturedCollection v-if="teasers[0]?.tier == 1" :stories="teasers" />
            <StoryCollection v-else :stories="teasers" />
          </template>
        </div>
      </template>
      <template v-else-if="searchStore.geoSuggestions.length">
        <h2 class="border-b-[3px] mb-3 border-timeout-red text-base-grey text-2xl font-serif leading-[1.25em]" v-html="appStringsStore.get('places')" />
        <ol class="flex flex-col divide-y divide-light-grey">
          <li v-for="suggestion in searchStore.geoSuggestions" :key="suggestion.mapbox_id" class="py-1">
            <button @click="placeSelected(suggestion.mapbox_id)"
              class="text-left py-2 block w-full hover:border-l-[3px] hover:pl-3 border-timeout-red flex space-x-3">
              <div>
                <img :src="GreyPin" alt="Pin icon" width="25" height="38" />
              </div>
              <p>
                <strong>{{ suggestion.name }}</strong><br />
                <span>{{ suggestion.place_formatted }}</span>
              </p>
            </button>
          </li>
        </ol>
      </template>
      <template v-else>
        <p v-html="appStringsStore.get('noResults')" />
      </template>
    </div>
    <RouterLink :to="closeLocation ? closeLocation.toString() : { name: `home:${languagesStore.getActiveLanguage().id}` }" :aria-label="appStringsStore.get('closePage')" :title="appStringsStore.get('closePage')"
      class="rounded-full bg-base-grey white p-1.5 absolute top-6 right-7 !mt-0 mb-0 z-20 close-button">
      <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import StoriesSearch from '@/components/StoriesSearch.vue'
import { useSearchStore } from '@/stores/search'
import { useLanguagesStore } from "@/stores/languages"
import { useRouter } from 'vue-router'
import GreyPin from '@/assets/images/icons/GreyPin.png'
import { useAppStringsStore } from "@/stores/appStrings";
import { usePinsStore } from "@/stores/pins";
import { useMapStore } from "@/stores/map";
import { type Pin } from "@/types";
import useTeaserGroups from "@/composables/useTeaserGroups";
import StoryCollection from "@/components/StoryCollection.vue";
import { useAppStateStore } from "@/stores/appState";
import FeaturedCollection from "@/components/FeaturedCollection.vue";

const searchStore = useSearchStore()
const languagesStore = useLanguagesStore()
const appStringsStore = useAppStringsStore()
const pinsStore = usePinsStore()
const mapStore = useMapStore()
const router = useRouter()
const teasersByType = ref<Pin[][]>()
const closeLocation = ref<string|boolean>()
const appStateStore = useAppStateStore()

// If this is not the first page, set the closeLocation to the previous page.
if (appStateStore.pageCount > 1 && pinsStore.activePin?.tier == 1 && pinsStore.activePin.type !== 'event') {
  const previousPage = router.options.history.state.back?.toString()
  closeLocation.value = previousPage ?? false
}
else {
  closeLocation.value = false
}

const placeSelected = async (mapboxId: string) => {
  const place = await searchStore.retrieveGeoSuggest(mapboxId)
  console.log("PLACE SELECTED", place)
  if (place) {
    await router.push({ name: `geoView:${languagesStore.getActiveLanguage().id}`, hash: `#${place.geometry.coordinates[1]},${place.geometry.coordinates[0]}` })
    mapStore.flyTo([place.geometry.coordinates[0], place.geometry.coordinates[1]])
  }
}

// Watch for content search results and group them by tier.
watch(() => [searchStore.contentSuggestions, appStateStore.pinsReady], async () => {
  if (!appStateStore.pinsReady) return

  let contentIds: number[] = []
  searchStore.contentSuggestions.forEach((contentSuggestion) => {
    contentIds.push(contentSuggestion.document.nid)
  })

  // Get the pins from the pinStore with these IDs.
  const pinResults = [...pinsStore.pinsMap.values()].filter((pin: Pin): pin is Pin => {
    return contentIds.includes(pin.id)
  })

  const sortedGroupedTeasers = useTeaserGroups(pinResults)

  // Limit tier 1 events. (Not Tastemakers).
  if (sortedGroupedTeasers?.[0]) {
    sortedGroupedTeasers[0] = sortedGroupedTeasers[0].filter((pin: Pin) => pin.type === 'event' && pin.tier === 1)
  }

  teasersByType.value = sortedGroupedTeasers
})

</script>
