import './assets/css/main.css'

import { createApp, watch } from 'vue'
import { createPinia } from 'pinia'
import { useAppStringsStore } from "@/stores/appStrings";
import { useLanguagesStore } from "@/stores/languages";
import { useAppStateStore } from "@/stores/appState";
import { useGeneralPagesStore } from "@/stores/generalPages";
import { createHead } from '@unhead/vue'
import * as Sentry from "@sentry/vue";

import App from './App.vue'
import router from './router'

// Swallow console logs in production.
if (import.meta.env.MODE === "production" && window.location.search !== "?debug")
  console.log = function no_console() {};

// Send console errors to Sentry.
const consoleError = console.error;
console.error = function(firstParam, ...args) {
  const response = consoleError.apply(console, args);
  Sentry.captureException(firstParam, { level: 'error' });
  return response;
};

const app = createApp(App)
const head = createHead()

Sentry.init({
  app,
  dsn: "https://1e703cbf5c191756f29331362be33988@www.supertreetrunk.com/8",
  environment: import.meta.env.MODE,
});

app.use(createPinia())
app.use(head)

const languagesStore = useLanguagesStore()
const appStringsStore = useAppStringsStore()
const generalPageStore = useGeneralPagesStore()
const appStateStore = useAppStateStore()

// Load the languages
languagesStore.fetch()
watch(() => languagesStore.languages, () => {
  languagesStore.setMetatags()
  appStateStore.watchLoaded()
  appStringsStore.fetchStrings()
  generalPageStore.fetchPages()
})

// Run the app once the localised strings are ready.
watch(() => appStringsStore.strings, () => {
  if (!Object.keys(appStringsStore.strings).length || !languagesStore.languages.length) return

  app.use(router())
  app.mount('#app')
})
