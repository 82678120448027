<template>
  <div class="gap-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 w-full">
    <template v-if="props.stories === false">
      <div v-for="i in 12" :key="i" class="animate-pulse bg-light-grey-alt w-full aspect-[4/5] rounded-md" />
    </template>
    <template v-else>
      <template v-for="(story, index) in props.stories" :key="story.id">
        <PinImage v-if="index < perPage * page" :size="PinSize.Small" :pin="story" />
      </template>
    </template>
  </div>
  <template v-if="props.stories">
    <div class="flex justify-center mt-3" v-if="perPage * page < props.stories.length">
      <a class="bg-light-grey hover:bg-light-grey-alt uppercase font-sansTitle text-base-gray rounded-full font-bold text-sm py-1 px-4" @click="nextPage" href="javascript:;" v-html="appStringsStore.get('seeMore')"></a>
    </div>
  </template>
</template>

<script setup lang="ts">
import { type Pin, PinSize } from '@/types'
import PinImage from '@/components/PinImage.vue'
import { ref } from 'vue'
import { Md5 } from 'ts-md5'
import { useAppStringsStore } from "@/stores/appStrings";

const appStringsStore = useAppStringsStore()

const props = defineProps<{
  stories: Pin[] | false
}>()

const nextPage = () => {
  page.value++
  // Store the page in local storage so that it persists across page loads, i.e. if the user clicks "back" in the browser.
  localStorage.setItem('listPage:' + listKey, `{"expires":${Date.now()+60000},"page":${page.value}}`)
}

const getPage = () => {
  const storeData = JSON.parse(localStorage.getItem('listPage:' + listKey) || '{}')
  if (storeData.expires !== undefined) {
    if (storeData.expires > Date.now()) {
      return storeData.page
    }
    else {
      localStorage.removeItem('listPage:' + listKey)
    }
  }
  return 1
}

const perPage = 12
const listKey = Md5.hashStr(JSON.stringify(props.stories))
const page = ref(getPage())
</script>
