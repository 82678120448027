<script setup lang="ts">
import { ref, watch, watchEffect } from 'vue'
import { type RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import { useAppStateStore } from "@/stores/appState";
import { useGeneralPagesStore } from "@/stores/generalPages";
import type { UseFetchResponse, ContentResponse } from "@/types";
import { Endpoint, useApiUrl } from '@/composables/useApiUrl';
import { useFetch } from "@vueuse/core";
import ContentLoading from "@/components/ContentLoading.vue";
import { useLanguagesStore } from "@/stores/languages";
import { usePinsStore } from "@/stores/pins";
import { useAppStringsStore } from "@/stores/appStrings";
import { useMobileSlideoutStore } from "@/stores/mobileSlideout";
import use404Redirect from "@/composables/use404Redirect";
import {useMapStore} from "@/stores/map";

const content = ref<ContentResponse>()
const ready = ref(false)
const route = useRoute()
const generalPagesStore = useGeneralPagesStore()
const languagesStore = useLanguagesStore()
const appStateStore = useAppStateStore()
const fetchURL = ref('')
const router = useRouter()
const homeLink = ref<RouteLocationRaw>({ name: `home:${languagesStore.getActiveLanguage().id}` })
const closeLocation = ref(homeLink)
const pinsStore = usePinsStore()
const appStringsStore = useAppStringsStore()
const mobileSlideoutStore = useMobileSlideoutStore()
const mapStore = useMapStore()

// If this is not the first page, set the closeLocation to the previous page.
const previousPage = router.options.history.state.back?.toString()
if (appStateStore.pageCount > 1 && previousPage) {
  closeLocation.value = { path: previousPage } ?? homeLink.value
}
else {
  closeLocation.value = homeLink.value
}

// Load the page content.
const { data: pageContent, isFinished: pageLoaded, execute: fetchData }: UseFetchResponse<[ContentResponse]> =
  useFetch<[ContentResponse]>(fetchURL, {
    async beforeFetch() {
      ready.value = false
    },
    immediate: false
  }).get().json()

const loadContent = async () => {
  if (!route.params.alias) return
  // Find the page in generalPagesStore.pages with an alias matching route.params.alias.
  const current_alias = languagesStore.removeURLPrefix('/' + route.params.alias, languagesStore.getActiveLanguage().urlPrefix)
  const page = generalPagesStore.pages.find(page => page.alias === current_alias)

  if (page) {
    fetchURL.value = useApiUrl(Endpoint.GeneralPages, { additionalPath: page.id.toString() })
    fetchData()
  }
  else if (generalPagesStore.pages.length) {
    use404Redirect(current_alias, 'content')
  }

  mapStore.reset()
}

watchEffect(() => {
  if (pageLoaded.value) {
    if (pageContent.value) {
      content.value = pageContent.value[0]

      if (route.name) {
        appStateStore.setHrefLangTags(content.value?.availableTranslations, route.name.toString(), router)
      }

      // Set the Meta tags.
      if (content.value.metatags) {
        appStateStore.setMetaTags(content.value.metatags)
      }
    }

    mobileSlideoutStore.scrollTop()
    ready.value = true
  }
})

// Initial Story load.
watch(() => appStateStore.pagesReady, () => {
  console.log('NAVIGATION', 'Loaded on a content item.')
  loadContent()
}, { deep: true })

// Changed from 1 page to another.
watch(() => route.path, () => {
  console.log('NAVIGATION', 'Moved from one content item to another.')
  loadContent()
})

// Open the mobile slideout when it's ready.
watch(() => mobileSlideoutStore.pane, () => {
  if (!mobileSlideoutStore.pane) return
  setTimeout(() => {
    mobileSlideoutStore.open()
  }, 1)
})

// Changed from a non-page to a page.
if (appStateStore.pagesReady) {
  console.log('NAVIGATION', 'Moved from a non-content item to a content item.')
  loadContent()
}
</script>

<template>
  <div class="prose space-y-6 pb-6 pt-12 px-[1.88rem]">
    <template v-if="ready">
      <h1 class="font-serif font-normal mb-6" v-html="content?.title" />
      <div v-html="content?.body" />
    </template>
    <template v-else>
      <ContentLoading />
    </template>

    <RouterLink :to="closeLocation" :aria-label="appStringsStore.get('closePage')" :title="appStringsStore.get('closePage')"
                class="rounded-full bg-base-grey white p-1.5 absolute top-4 right-4 m-2 z-20 close-button" @click="pinsStore.clearPinSelected()">
      <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
           stroke-linejoin="round">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </RouterLink>
  </div>
</template>
