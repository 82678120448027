import type { Coordinates, Pin } from "@/types";

const radians = (degree: number): number => degree * Math.PI / 180;

export function geojson2coords(geojson: [number, number]) {
  return {
    lat: geojson[1],
    lng: geojson[0]
  } as Coordinates;
}

export function coords2geojson(coords: Coordinates) {
  return [coords.lng, coords.lat] as [number, number];
}

/**
 * Calculates the distance between the given coordinates.
 *
 * @param coords1
 * @param coords2
 */
export function haversineDistance(coords1: Coordinates, coords2: Coordinates) {
  const earthRadiusKm = 6372.8; // km
  const dlat = radians(coords2.lat - coords1.lat);
  const dlon = radians(coords2.lng - coords1.lng);
  const a = Math.sin(dlat / 2) * Math.sin(dlat / 2) + Math.sin(dlon / 2) * Math.sin(dlon / 2) * Math.cos(radians(coords1.lat)) * Math.cos(radians(coords2.lat))
  const c = 2 * Math.asin(Math.sqrt(a));

  return earthRadiusKm * c;
}

export function isDistanceLessThanRange(coords1: Coordinates, coords2: Coordinates, rangeKm: number) {
  return haversineDistance(coords1, coords2) < rangeKm
}

/**
 * Finds the closest item in an array of geo coordinates to the given search coordinates.
 *
 * @param list Array of points
 * @param search
 */
export function sortPinsByProximity(list: Pin[], search: Coordinates) {
  return list.sort((a: Pin, b: Pin) => {
    const aDist = haversineDistance(search, geojson2coords(a.coordinates));
    const bDist = haversineDistance(search, geojson2coords(b.coordinates));
    return aDist - bDist;
  })
}

/**
 * Sorts the given list of pins by proximity to each other.
 *
 * @param list Array of pins
 */
export function sortPinsByProximityToEachother(list: Pin[]) {
  if (list.length === 0) return [];
  const sortedPins: Pin[] = [];
  let currentPin = list[0];
  const remainingPins = list.slice(1);
  sortedPins.push(currentPin);
  while (remainingPins.length > 0) {
    const currentPinCoords = geojson2coords(currentPin.coordinates)
    let nearestPinIndex = 0;
    let nearestDistance = haversineDistance(currentPinCoords, geojson2coords(remainingPins[0].coordinates));

    for (let i = 1; i < remainingPins.length; i++) {
      const distance = haversineDistance(currentPinCoords, geojson2coords(remainingPins[i].coordinates));
      if (distance < nearestDistance) {
        nearestDistance = distance;
        nearestPinIndex = i;
      }
    }

    currentPin = remainingPins[nearestPinIndex];
    sortedPins.push(currentPin);
    remainingPins.splice(nearestPinIndex, 1);
  }
  return sortedPins;
}

