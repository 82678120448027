<template>
  <div class="animate-pulse w-full">
    <div class="flex flex-col space-y-6">
      <div>
        <div class="h-7 bg-mid-grey-2 mb-3"></div>
      </div>
      <div>
        <div class="h-7 bg-mid-grey-2 w-1/3 mb-3"></div>
      </div>

      <div class="h-4 bg-light-grey-alt w-2/3 my-6"></div>

      <div class="space-y-3">
        <div class="grid grid-cols-3 gap-4">
          <div class="h-4 bg-light-grey-alt col-span-2"></div>
          <div class="h-4 bg-light-grey-alt col-span-1"></div>
        </div>

        <div class="h-4 bg-light-grey-alt"></div>

        <div class="grid grid-cols-3 gap-4">
          <div class="h-4 bg-light-grey-alt col-span-1"></div>
          <div class="h-4 bg-light-grey-alt col-span-2"></div>
        </div>

        <div class="h-4 bg-light-grey-alt"></div>
        <div class="h-4 bg-light-grey-alt w-2/3"></div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
