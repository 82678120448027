<template>
  <div v-if="stories">
    <div class="flex flex-col divide-y divide-mid-grey">
      <div v-for="story in stories" :key="story.id">
        <RouterLink class="flex gap-6 py-3 hover:bg-lighter-grey" :to="{
          name: `stories:${languagesStore.getActiveLanguage().id}`,
          params: { alias: story.alias }
        }">
          <div class="min-w-[80px]">
            <img width="80" height="96" class="rounded-md" :src="story.image" alt="">
          </div>
          <div class="text-lg">
            <h3 class="font-bold text-[20px]" v-html="story.title" />
            <p v-html="story.subTitle" />
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type Pin } from '@/types'
import {useLanguagesStore} from "@/stores/languages";

const languagesStore = useLanguagesStore()

defineProps<{
  stories: Pin[]
}>()
</script>

