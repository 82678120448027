import { defineStore } from 'pinia'
import { watchEffect } from "vue"
import { useFetch } from "@vueuse/core"
import { useAppStateStore } from './appState';
import { Endpoint, useApiUrl } from '@/composables/useApiUrl';
import type { ContentListResponse, UseFetchResponse } from "@/types";
import { useLanguagesStore } from "@/stores/languages";

export const useGeneralPagesStore = defineStore('generalPages', {
  state: () => ({
    pages: [] as ContentListResponse,
  }),
  actions: {
    fetchPages() {
      // Fetch all the app strings.
      const url = useApiUrl(Endpoint.GeneralPages)
      const { data: pageContent, isFinished }: UseFetchResponse<ContentListResponse> = useFetch(url).get().json()
      const languagesStore = useLanguagesStore()

      watchEffect(() => {
        if (isFinished.value && pageContent.value) {
          const appStateStore = useAppStateStore()

          // Loop through the pages and remove the language prefixes.
          pageContent.value.forEach((page) => {
            page.alias = languagesStore.removeURLPrefix(page.alias, languagesStore.getCurrentLanguageURLPrefix())
          })

          this.pages = pageContent.value
          console.log('PAGES', this.pages)
          appStateStore.pagesHaveLoaded()
        }
      })
    }
  }
})
