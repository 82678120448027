<script setup lang="ts">
import { useAppStringsStore } from '@/stores/appStrings';
import { useLanguagesStore } from '@/stores/languages'
import { useAppStateStore } from "@/stores/appState";
import { onMounted } from "vue";
import logoImageDefault from '@/assets/images/logo.webp'
import logoImageFrench from '@/assets/images/logo-French.webp'

const languagesStore = useLanguagesStore()
const appStringsStore = useAppStringsStore()
const appStateStore = useAppStateStore()

const logo = languagesStore.getActiveLanguage().id === 'fr' ? logoImageFrench : logoImageDefault

onMounted(() => {
  appStateStore.setMetaTags({
    title: appStringsStore.get('pageNotFoundTitle'),
    robots: 'noindex'
  })
})
</script>

<template>
  <div class="duration-0 motion-safe:duration-500 transition-all bg-base-grey flex flex-col justify-center items-center h-full fixed top-0 left-0 w-full text-white text-center">
    <img alt="Foodmarks logo" :src="logo" />
    <h1 class="text-3xl mt-12 mb-4">404</h1>
    <p class="text-lg"><RouterLink :to="{ name: `home:${languagesStore.getActiveLanguage().id}` }"><span v-html="appStringsStore.get('pageNotFoundText')" /></RouterLink></p>
  </div>
</template>
