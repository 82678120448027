import { defineStore } from 'pinia'
import { watchEffect } from "vue"
import { useFetch } from "@vueuse/core"
import { useAppStateStore } from './appState';
import { Endpoint, useApiUrl } from '@/composables/useApiUrl';

export const useAppStringsStore = defineStore('appStrings', {
  state: () => ({
    strings: {} as Record<string, string>
  }),
  actions: {
    fetchStrings() {
      // Fetch all the app strings.
      const url = useApiUrl(Endpoint.AppStrings)
      const { data: stringContent, isFinished } = useFetch(url).get().json()

      watchEffect(() => {
        if (isFinished.value && stringContent.value) {
          const appStateStore = useAppStateStore()
          appStateStore.stringsHaveLoaded()
          this.strings = stringContent.value
          console.log('APP STRINGS', this.strings)
        }
      })
    },

    get(key: string, defaultValue: string = ''): string {
      return this.strings[key] ?? defaultValue;
    }
  }
})
