import { useLanguagesStore } from "@/stores/languages"

export enum Endpoint {
  HomePage = 'home-page',
  GeoPins = 'geo-pins',
  AppStrings = 'app-strings',
  GeneralPages = 'general-pages',
  Languages = 'languages',
  Redirects = 'redirects',
}

interface ApiEndpoint {
  path: string
  requiresAdditionalPath: boolean
  requiresParams: boolean
  requiresLanguage: boolean
}

type Api = {
  [key in Endpoint]: ApiEndpoint
}

const api: Api = {
  [Endpoint.HomePage]: {
    path: 'general-pages',
    requiresAdditionalPath: true,
    requiresParams: false,
    requiresLanguage: true,
  },
  [Endpoint.GeoPins]: {
    path: 'geo-pins',
    requiresAdditionalPath: false,
    requiresParams: false,
    requiresLanguage: true,
  },
  [Endpoint.AppStrings]: {
    path: 'app-strings',
    requiresAdditionalPath: false,
    requiresParams: false,
    requiresLanguage: true,
  },
  [Endpoint.GeneralPages]: {
    path: 'general-pages',
    requiresAdditionalPath: false,
    requiresParams: false,
    requiresLanguage: true,
  },
  [Endpoint.Languages]: {
    path: 'languages',
    requiresAdditionalPath: false,
    requiresParams: false,
    requiresLanguage: false,
  },
  [Endpoint.Redirects]: {
    path: 'redirects',
    requiresAdditionalPath: false,
    requiresParams: false,
    requiresLanguage: true,
  }
}

type EndpointOptions = {
  additionalPath?: string,
  params?: { [key: string]: string }
}

// Keeps the url updated with the current language
const useApiUrl = (endpoint: Endpoint, options?: EndpointOptions) => {
  const languagesStore = useLanguagesStore()

  const { additionalPath, params } = options || {}

  const apiEndpoint = api[endpoint]
  const pathParts = []

  if (apiEndpoint.requiresLanguage) pathParts.push(
    // we need to remove slashes here as router links require this
    // but api urls don't
    languagesStore.getURLModifier().replace('/', '')
  )

  pathParts.push(apiEndpoint.path)

  console.log(options)
  if (apiEndpoint.requiresAdditionalPath && !additionalPath) {
    throw new Error(`Path required for the ${endpoint} endpoint.`)
  }

  if (options?.additionalPath) pathParts.push(additionalPath)

  const path = pathParts.filter(Boolean).join('/')

  if (apiEndpoint.requiresParams && !params) {
    throw new Error(`Params required for the ${endpoint} endpoint.`)
  }

  if (options?.params) pathParts.push((new URLSearchParams(params)).toString())

  if (apiEndpoint.requiresAdditionalPath) pathParts.push(path)

  return '/api/' + path
}

export { useApiUrl }
