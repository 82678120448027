<template>
  <div class="space-y-6 py-6 mx-[1.88rem]">
    <div class="prose">
      <h1 class="font-serif font-normal mb-6" v-html="appStringsStore.get('favouritesHeader')" />
      <p v-html="appStringsStore.get('favouritesBody')" />
    </div>

    <template v-if="pins.length">
      <div v-for="(teasers, index) in teasersByType" :key="index" class="mb-7">
        <template v-if="teasers.length">
          <h2 class="border-b-[3px] mb-3 border-timeout-red font-serif text-2xl leading-[1.25em] font-normal"
              v-html="teasers[0]?.tier == 1 ? appStringsStore.get('tier1Title') : 'Foodmarks'"></h2>
          <FeaturedCollection v-if="teasers[0]?.tier == 1" :stories="teasers" />
          <StoryCollection v-else :stories="teasers" />
        </template>
      </div>
    </template>

    <p v-html="appStringsStore.get('favouritesEmpty')" v-else />

    <RouterLink :to="closeLocation ? closeLocation.toString() : { name: `home:${languagesStore.getActiveLanguage().id}` }" :aria-label="appStringsStore.get('closePage')" :title="appStringsStore.get('closePage')"
                class="rounded-full bg-base-grey white p-1.5 absolute top-0 right-7 z-20 close-button" @click="pinsStore.clearPinSelected()">
      <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
           stroke-linejoin="round">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import StoryCollection from '@/components/StoryCollection.vue';
import { usePinsStore } from '@/stores/pins';
import type { Pin } from '@/types';
import { useStorage } from '@vueuse/core';
import { onMounted, ref, watchEffect } from 'vue';
import { useAppStringsStore } from '@/stores/appStrings';
import { useRouter } from 'vue-router';
import { useAppStateStore } from "@/stores/appState";
import { useLanguagesStore } from "@/stores/languages";
import FeaturedCollection from "@/components/FeaturedCollection.vue";
import useTeaserGroups from "@/composables/useTeaserGroups";
import { useMobileSlideoutStore } from "@/stores/mobileSlideout";
import {useMapStore} from "@/stores/map";

const storage = useStorage('foodmarksFavourites', new Set<string>())
const pins = ref<Pin[]>([])
const appStringsStore = useAppStringsStore()
const pinsStore = usePinsStore()
const appStateStore = useAppStateStore()
const closeLocation = ref<string|boolean>()
const router = useRouter()
const languagesStore = useLanguagesStore()
const teasersByType = ref<Pin[][]>()
const mobileSlideoutStore = useMobileSlideoutStore()
const mapStore = useMapStore()

// If this is not the first page, set the closeLocation to the previous page.
if (appStateStore.pageCount > 1) {
  const previousPage = router.options.history.state.back?.toString()
  closeLocation.value = previousPage ?? false
}
else {
  closeLocation.value = false
}

onMounted(() => {
  mobileSlideoutStore.scrollTop()
  mapStore.reset()
})

watchEffect(() => {
  pins.value = []
  storage.value.forEach((pinId) => {
    const foundPin = pinsStore.pinsMap.get(Number(pinId))
    // Likely that the pin has been removed
    if (!foundPin) return;
    pins.value.push(foundPin)

    const sortedGroupedTeasers = useTeaserGroups(pins.value)

    // Limit tier 1 events. (Not Tastemakers).
    if (sortedGroupedTeasers?.[0]) {
      sortedGroupedTeasers[0] = sortedGroupedTeasers[0].filter((pin: Pin) => pin.type === 'event' && pin.tier === 1)
    }

    mobileSlideoutStore.scrollTop()
    teasersByType.value = sortedGroupedTeasers
  })
})
</script>
