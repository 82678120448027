import { ref, watchEffect } from 'vue';
import { useWindowSize } from '@vueuse/core'
import isMobile from '@/utils/isMobile';

export default function useIsMobile() {
  const { width } = useWindowSize();
  const isMobileRef = ref(true);

  watchEffect(() => {
    isMobileRef.value = isMobile(width.value)
  });

  return isMobileRef;
}
