<template>
  <ol class="flex items-center justify-center gap-6 mt-6 lg:mt-0 xl:-mt-6 overflow-hidden">
    <template v-if="loading">
      <li class="animate-pulse bg-light-grey-alt w-[152px] h-[308px] rounded-md" />
      <li class="animate-pulse bg-light-grey-alt w-[166px] h-[336px] rounded-md" />
      <li class="animate-pulse bg-light-grey-alt w-[152px] h-[308px] rounded-md" />
    </template>
    <template v-else-if="pin?.navImage">
      <li v-if="previousPin" class="overflow-hidden relative">
        <RouterLink :to="{
          name: `stories:${languagesStore.getActiveLanguage().id}`,
          params: { alias: previousPin.alias }
        }"
                    :title="previousPin.title"
        >
          <img :src="previousPin?.navImage" :alt="previousPin?.title" class="rounded-md float-right max-w-none opacity-50 hover:opacity-70 transition-opacity" width="152" height="308" />
          <div class="absolute right-0 top-0 pointer-events-none arrow">
            <img :src="previousPin?.navImage" :alt="previousPin?.title" class="rounded-md max-w-none absolute top-0" width="152" height="308" />
          </div>
        </RouterLink>
      </li>
      <li><img :src="pin?.navImage" :alt="pin?.title" class="rounded-md max-w-none" width="166" height="336" /></li>
      <li v-if="nextPin" class="overflow-hidden relative">
        <RouterLink :to="{
          name: `stories:${languagesStore.getActiveLanguage().id}`,
          params: { alias: nextPin.alias }
        }"
                    :title="nextPin.title"
        >
          <img :src="nextPin?.navImage" :alt="nextPin?.title" class="rounded-md max-w-none opacity-50 hover:opacity-70 transition-opacity" width="152" height="308" />
          <div class="absolute top-0 pointer-events-none rotate-180 arrow">
            <img :src="nextPin?.navImage" :alt="nextPin?.title" class="rounded-md max-w-none absolute top-0 rotate-180" width="152" height="308" />
          </div>
        </RouterLink>
      </li>
    </template>
  </ol>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue"
import { usePinsStore } from "@/stores/pins";
import type {Pin} from "@/types";
import { useLanguagesStore } from "@/stores/languages";

const pinsStore = usePinsStore()
const languagesStore = useLanguagesStore()
const pin = ref(undefined as Pin | undefined)
const previousPin = ref(undefined as Pin | undefined)
const nextPin = ref(undefined as Pin | undefined)
const loading = ref(true)

const props = defineProps<{
  id: number | false
}>()

onMounted(() => {
  if (!props.id) return
  loading.value = false

  const pinSearch = [...pinsStore.pinsMap.values()].find((pin: Pin) => pin.id === props.id)
  if (pinSearch && pinSearch.navImage) {
    pin.value = pinSearch
    let index = pinsStore.pinsByDistance.findIndex((p: Pin) => p.id === pinSearch.id)

    // Get the previous pin by iterating back in the array until we find one with a navImage.
    for (let x = index; x >= -1; x--) {
      if (x === -1) x = pinsStore.pinsByDistance.length - 1
      if (pinsStore.pinsByDistance[x].navImage && pinsStore.pinsByDistance[x].id !== pinSearch.id) {
        previousPin.value = pinsStore.pinsByDistance[x]
        break
      }
    }

    // Get the next pin by iterating forward in the array until we find one with a navImage.
    for (let x = index; x <= pinsStore.pinsByDistance.length; x++) {
      if (x === pinsStore.pinsByDistance.length) x = 0
      if (pinsStore.pinsByDistance[x].navImage && pinsStore.pinsByDistance[x].id !== pinSearch.id) {
        nextPin.value = pinsStore.pinsByDistance[x]
        break
      }
    }
  }
})
</script>

<style scoped>
.arrow {
  width: 152px;
  height: 308px;
  mask-repeat: no-repeat;
  mask-position: top 50% right 10px;
  mask-image: url(@/assets/images/chevron.svg);
}
@media (min-width: 470px) {
  .arrow {
    mask-position: center;
  }
}
</style>

