import type { Pin } from "@/types";

export default function useTeaserGroups(teasers: Pin[], removeArticles: Boolean = true): Pin[][] | undefined {
  if (!teasers || teasers.length === 0) return undefined

  // Loop through teasers and group them by tier as an array.
  const groupedTeasers: Pin[][] = teasers.reduce((accumulator: Pin[][], currentPin: Pin) => {
    const accumulatorKey = currentPin.tier === 1 ? 0 : 1
    if (accumulator.length != 2) accumulator = [[],[]]

    // Don't add non-event tier 1s.
    if (accumulatorKey === 0 && currentPin.type !== 'event') return accumulator

    // Don't include "Content" pins.
    if (removeArticles && currentPin.type === 'article') return accumulator

    if (!accumulator[accumulatorKey]) {
      accumulator[accumulatorKey] = [currentPin]
    }
    else {
      accumulator[accumulatorKey].push(currentPin)
    }
    return accumulator
  }, []);

  return groupedTeasers
}
