import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid';
import type { PinGeoJsonFeature, ContentSuggestion } from '@/types';
import Typesense from 'typesense';
import type { SearchResponseHit } from 'typesense/lib/Typesense/Documents';
import { onBeforeUnmount, onMounted, type Ref } from 'vue';
import { useLanguagesStore } from './languages';
import { useRoute } from "vue-router";

const SEARCHBOX_API_URL = 'https://api.mapbox.com/search/searchbox/'

type SearchSuggestion = {
  name: string;
  name_preferred: string;
  mapbox_id: string;
  feature_type: string;
  place_formatted: string;
}

type SuggestionResponse = {
  suggestions: SearchSuggestion[]
  attribution: string;
}

type RetrieveResponse = {
  features: PinGeoJsonFeature[]
  attribution: string;
  type: 'FeatureCollection';
}


type CursorDirection = "forward" | "backward" | "none" | null;

/**
  * Helper class to store the cursor position of the search input
**/
class InputCursorPositionInfo {
  start: number | null;
  end: number | null;
  direction: CursorDirection
  constructor(start: number | null, end: number | null, direction: CursorDirection) {
    this.start = start;
    this.end = end;
    this.direction = direction;
  }

  // cursor direction can't be null, so change return type so that it returns
  // undefined instead
  asSelectionRange(): [number | null, number | null, Exclude<CursorDirection, null> | undefined] {
    return [this.start, this.end, this.direction ?? undefined]
  }

  updateFromInput(input: HTMLInputElement) {
    this.start = input.selectionStart;
    this.end = input.selectionEnd;
    this.direction = input.selectionDirection;
  }
}

const isHitWithContentDocument = (hit: any): hit is SearchResponseHit<ContentSuggestion> => {
  const doc = hit.document;
  return doc && doc.id && doc.langcode && doc.location && doc.nid && doc.title && doc.uuid;
}

const allHitsHaveContentDocument = (hits: any[]): hits is SearchResponseHit<ContentSuggestion>[] =>
  hits.every(isHitWithContentDocument)

export const useSearchStore = defineStore('search', {
  state: () => ({
    searchQuery: undefined as string | undefined,
    accessToken: undefined as string | undefined,
    geoSuggestions: [] as SearchSuggestion[],
    contentSuggestions: [] as SearchResponseHit<ContentSuggestion>[],
    searchSessionUUID: undefined as string | undefined,
    _cursorPosition: new InputCursorPositionInfo(null, null, null),
  }),
  actions: {
    init() {
      // Read https://docs.mapbox.com/api/search/search-box/#get-suggested-results
      if (!this.searchSessionUUID) this.searchSessionUUID = uuidv4();
      this.accessToken = import.meta.env['VITE_MAPBOX_GL_ACCESS_TOKEN']
      if (!this.accessToken) {
        throw new Error('Mapbox GL access token is missing')
      }

      // Set the term to the string in the URL if it exists.
      const route = useRoute()
      this.searchQuery = route.params?.query as string | undefined;
    },
    initSearchRef(input: Ref<HTMLInputElement | undefined>) {
      onMounted(() => {
        input.value?.setSelectionRange(...this._cursorPosition.asSelectionRange());
      })

      onBeforeUnmount(() => {
        if (!input.value) return;
        this._cursorPosition.updateFromInput(input.value);
      });

    },
    async search(query: string) {
      await this.contentSearch(query);
    },
    async geoSearch(query: string) {
      if (!this.accessToken || !this.searchSessionUUID) {
        throw new Error('Search store is not initialized before query')
      }

      this.searchQuery = query
      const endpoint = new URL(`${SEARCHBOX_API_URL}v1/suggest`)
      endpoint.searchParams.append('access_token', this.accessToken)
      endpoint.searchParams.append('session_token', this.searchSessionUUID)
      endpoint.searchParams.append('types', 'country,region,postcode,district,place,locality,neighborhood,address')
      endpoint.searchParams.append('q', query)

      const res = await fetch(endpoint)

      if (!res.ok) {
        // TODO: Handle this in the UI
        throw new Error('Search API error, non-200 response from suggest')
      }

      const json = await res.json().catch(() => {
        // TODO: Handle this in the UI
        throw new Error('Search API error, invalid json response from suggest')
      }) as SuggestionResponse | undefined;

      if (!json || !json.suggestions) {
        // TODO: Handle this in the UI
        throw new Error('Search API error, no suggestions in response from suggest')
      }

      this.geoSuggestions = json.suggestions;
    },
    async retrieveGeoSuggest(id: string): Promise<PinGeoJsonFeature | undefined> {
      if (!this.accessToken || !this.searchSessionUUID) {
        throw new Error('Search store is not initialized before retrieve')
      }

      const endpoint = new URL(`${SEARCHBOX_API_URL}v1/retrieve/${id}`)

      endpoint.searchParams.append('access_token', this.accessToken)
      endpoint.searchParams.append('session_token', this.searchSessionUUID)

      const res = await fetch(endpoint)

      if (!res.ok) {
        throw new Error('Search API error, non-200 response from retrieve')
      }

      const json = await res.json().catch(() => {
        throw new Error('Search API error, invalid json response from retrieve')
      }) as RetrieveResponse | undefined;

      return json?.features[0]
    },
    async contentSearch(query: string) {
      const languagesStore = useLanguagesStore()
      const typesenseClient = new Typesense.Client({
        nodes: [{
          host: import.meta.env['VITE_TYPESENSE_HOST'],
          port: import.meta.env['VITE_TYPESENSE_PORT'],
          protocol: import.meta.env['VITE_TYPESENSE_PROTOCOL'],
        }],
        apiKey: import.meta.env['VITE_TYPESENSE_API_KEY'],
        connectionTimeoutSeconds: 2,
      });

      const searchParameters = {
        q: query,
        query_by: 'title,subtitle,body,standfirst,translateable_standfirst,address_description,keywords,author_name,author_username',
        filter_by: `langcode:${languagesStore.getActiveLanguage().id}`,
        per_page: 250
      };

      const contentRes = await typesenseClient.collections('geopins').documents().search(searchParameters)

      if (!contentRes || !contentRes.hits) {
        //  TODO: Handle this in the UI
        throw new Error('Search API error')
      }

      if (!allHitsHaveContentDocument(contentRes.hits)) {
        // TODO: Handle this in the UI
        throw new Error('NOT IMPLEMENTED: Search API, no hits in response from search')
      }

      this.contentSuggestions = contentRes.hits;
      // If no results, run the geo search.
      if (this.contentSuggestions.length === 0) {
        await this.geoSearch(query);
      }
    },
  }
})

