<template>
  <div class="relative w-full">
    <label for="search" class="sr-only" v-html="appStringsStore.get('searchButton')" />
    <input ref="input" @keydown="handleInputKey" type="text" :value="searchStore.searchQuery"
      class="placeholder-base-grey h-12 leading-7 w-full pl-12 border border-base-grey rounded-[10px] focus:ring-timeout-red focus:border-timeout-red focus:outline-none"
      :placeholder="appStringsStore.get('searchButton')"
      id="search" />
    <button class="absolute left-4 top-[.85rem] cursor-pointer" @click="handleSubmit"><img class="w-[22px] h-[22px]" :src="SearchIcon" :alt="appStringsStore.get('searchButton')" /></button>
  </div>
</template>

<script setup lang="ts">
import SearchIcon from '@/assets/images/Search.svg'
import { useRoute, useRouter } from 'vue-router'
import { useSearchStore } from '@/stores/search'
import { onMounted, ref } from 'vue';
import { useLanguagesStore } from "@/stores/languages";
import {useAppStringsStore} from "@/stores/appStrings";

const searchStore = useSearchStore()
const languagesStore = useLanguagesStore()
const router = useRouter()
const route = useRoute()
const appStringsStore = useAppStringsStore()

const MIN_CHARS_TO_SEARCH = 2

const input = ref<HTMLInputElement>()

searchStore.init()
searchStore.initSearchRef(input)

const handleSubmit = () => {
  if (!searchStore.searchQuery || !input.value) return
  guardedSearch(input.value)
}

const handleInputKey = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    const target = e.target as HTMLInputElement
    guardedSearch(target)
  }
}

onMounted(() => {
  if (route.params.query) {
    searchStore.search(route.params.query.toString())
  }
})

const guardedSearch = (target: HTMLInputElement) => {
  if (target.value.length < MIN_CHARS_TO_SEARCH) return
  searchStore.search(target.value)
  router.push({ name: `search:${languagesStore.getActiveLanguage().id}`, params: {query: target.value} })
}

</script>
